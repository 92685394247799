import { Box } from 'grommet';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setQuery } from '../data-store';
import ke_logo from '../images/knowledge_enable_logos_ke_nighttime_logo_x395_y55.png';
import UserForm from './UserForm';

export default function UserProfile(props: any) {
	const { userInfo, loggedIn } = props
	const dispatch = useDispatch()

	return (
		<>{loggedIn ?
			<Box height="100%">
				<Box
					direction="column"
					justify="center"
					align="center"
					gap="small"
					pad={{ vertical: '2ch', horizontal: '6ch' }}
					style={{ minWidth: '60%' }}
					animation={{
						type: "slideDown",
						delay: 0,
						duration: 800,
						size: "xlarge"
					}}
				>
					<Link to="/" onClick={() => dispatch(setQuery(""))}>	<img src={ke_logo} alt="KnowledgeEnable" style={{ cursor: 'pointer' }} /></Link>
				</Box>
				<UserForm userInfo={userInfo} loggedIn={loggedIn} />
			</Box> : <Box height="100%" background='white'></Box>

		}</>
	);
}
