import { Anchor, Box, Grid } from 'grommet';
import { Close, Next, Previous } from 'grommet-icons';
import React from 'react';
import styled from 'styled-components';
import { BookmarkButton } from '../../Bookmarks';
import LikeButton, { update_score } from '../../LikeButton';
import { COLOR_BRAND } from '../../styling/styles';
import ReportBroken from '../ReportBroken';
import { Document } from '../SearchResults';
import Auntminnie, { AuntMinnieCase } from './AuntminnieCase';
import Radiopaedia, { RadiopaediaCase } from './RadiopaediaCase';

export type CaseSources = 'radiopedia' | 'auntminnie'

export interface BaseCase extends Document {
    _index: CaseSources;
    _source: {}
    _type: 'case';
}

export type Case = RadiopaediaCase | AuntMinnieCase

export type CaseProps = {
    document: Case;
    onClose: () => void;
    onPrev?: () => void;
    onNext?: () => void;
    onKnowledgeEnable?: () => void
}

export default function CaseDisplay(props: CaseProps){
    const {document, onClose, onPrev, onNext, onKnowledgeEnable} = props
    const {Left, Right} = CaseSwitch(document)

    return(
        <Grid 
            fill 
            areas={[
                { name: "top", start: [0,0], end: [1, 0] },
                { name: "left", start: [0, 1], end: [0, 1] },
                { name: "right", start: [1, 1], end: [1, 1] }
            ]}
            columns={["flex", "33%"]}
            rows={["77px", "flex"]}
        >
            <Box gridArea="top" direction="row" justify="between" align="center" pad="medium">
                <span>
                    <PrevCase onClick={onPrev} enable={typeof onPrev !== 'undefined'}/>
                    <NextCase onClick={onNext} enable={typeof onNext !== 'undefined'}/>
                </span>
                <Box align="center" direction="row" gap="large">
                    {onKnowledgeEnable && 
                    <Anchor 
                        size="medium" 
                        margin={{vertical: 'medium'}} 
                        onClick={onKnowledgeEnable}
                    >
                        Click Here to switch to Knowledge Enable mode
                    </Anchor>}
                    <CloseCase onClick={onClose}/>
                </Box>
            </Box>
            <Box fill direction="column" gridArea="left" overflow={{horizontal: 'hidden', vertical: 'auto'}} gap="small" pad={{vertical: 'small', horizontal: 'medium'}}>
                {Left}
            </Box>
            <Box gridArea="right" pad={{horizontal: 'medium'}} gap="xsmall">
                <Box border={{color: "brand"}} overflow={{horizontal: 'hidden', vertical: 'auto'}} style={{minHeight: '33vh'}}>
                    <div style={{backgroundColor: COLOR_BRAND, textAlign: 'center', color: 'black'}}>Case Information</div>
                    <StyledGrid>
                        {Right}
                    </StyledGrid>
                </Box>
                <Box direction="row" justify="between" pad={{vertical: "small"}} gap="small" height="100%">
                    <Box direction="row" justify="between" gap="small" height="100%">
                        <LikeButton
                            id={document._id}
                            index={document._index}
                            score={document._source.popularity_score}
                            direction='row'
                            liked={document._liked}
                            onLike={(delta) => update_score(document, delta)}
                        />
                    <BookmarkButton id={document._id} bookmarked={document._bookmarked}/>
                    </Box>
                    <Box direction="row" justify="end">
                        <ReportBroken index={document._index} id={document._id} url={document._source.url}/>
                    </Box>
                </Box>
            </Box>
        </Grid>
    )
}

type TitleProps = {
    text: string,
    url: string,
    index:string
}
export function Title(props: TitleProps){
    const {text, url, index} = props
    if (index === 'auntminnie') {
        return(
            <Anchor size="xxlarge" color="brand" onClick={() => window.open(`http://${url}`)}>{text}</Anchor>    
        )
    }
    else {
        return(
            <Anchor size="xxlarge" color="brand" onClick={() => window.open(url)}>{text}</Anchor>    
        )
    }
    
}

function CaseSwitch(document: Case) {
    switch(document._index) {
        case('radiopedia'):
            return Radiopaedia(document)
        case('auntminnie'):
            return Auntminnie(document)
    }
}

export type RowProps = {
    label: string,
    field: string | number | JSX.Element
}
export function Row({label, field}: RowProps) {
    return (
        <>
            <Label>{label}</Label>
            <Field>{field}</Field>
        </>
    )
}

const CloseCase = styled(Close)`
    height: 29px;
    stroke: ${COLOR_BRAND};
    &:hover {
        stroke: white;
        cursor: pointer;    
    }
`
const PrevCase = styled(Previous)<{enable: boolean}>`
    height: 29px;
    stroke: ${p => p.enable ? COLOR_BRAND : 'grey'};
    &:hover {
        stroke: ${p => p.enable ? 'white' : 'grey'};
        cursor: ${p => p.enable ? 'pointer' : ''};    
    }
`
const NextCase = styled(Next)<{enable: boolean}>`
    height: 29px;
    stroke: ${p => p.enable ? COLOR_BRAND : 'grey'};
    &:hover {
        stroke: ${p => p.enable ? 'white' : 'grey'};
        cursor: ${p => p.enable ? 'pointer' : ''};    
    }
`

const StyledGrid = styled.div`
    display: grid;
    grid-template-columns: 45% 55%;
    grid-template-rows: auto;
    row-gap: 12px;
    padding: 6px;
`

export const Label = styled.span`
    display: flex;
    grid-column: 1;
    align-self: left;
    justify-content: flex-start;
`

export const Field = styled.span`
    display: flex;
    grid-column: 2;
    justify-content: flex-end;
    flex-wrap: wrap;
    text-align: end;
`

export const Frame = styled.div`
    align-self: center; 
    margin-top: 24px; 
    max-width: 600px;
    max-height: 600px;
    cursor: pointer;
    box-sizing: border-box;
    // &:hover {
    //     border: 1px solid white;
    // }
`