import React from 'react'
import SearchBar from './SearchBar';
import ke_logo from './images/knowledge_enable_logos_ke_nighttime_logo_x395_y55.png'
import ke_logo_small from './images/rtm_cube.png'
import { Link } from 'react-router-dom';
import { Box } from 'grommet';
import { useWindowSize } from './util';
import { useDispatch } from 'react-redux';
import { setQuery} from './data-store';

export default function Header() {
    const [width] = useWindowSize()
    const img_src = width < 1280 ? ke_logo_small : ke_logo

    const dispatch = useDispatch()

    return( 
        <Box direction="row" align="center" pad={{top: "medium", right: "xlarge", bottom: "medium", left: "medium"}} border="bottom">
        <Link to="/" onClick={()=>dispatch(setQuery(""))}><img src={img_src} alt="logo" style={{cursor: 'pointer'}} /></Link>
            <SearchBar
                style={{flex: 1, maxHeight: '55px', maxWidth: "75%"}}
            />
        </Box>
    )
}