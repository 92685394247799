import { Store } from "./reducers";

export const getQuery = (state: Store) => state.query
export const getContentType = (state: Store) => state.contentType
export const getPageSize = (state: Store) => state.pageSize
export const getHomeType = (state: Store) => state.home
export const getToggleMode = (state: Store) => state.toggleMode
export const getSearchMode = (state: Store) => state.searchMode
export const getDeleteDirection = (state: Store) => state.deleteDirection
export const getSortMode = (state: Store) => state.sortMode
export const getAuntminnieAuth = (state: Store) => state.auntminnieAuth

export const getSearchParams = (state: Store) => {
    const {query, contentType, page, pageSize, searchMode, filters, deleteDirection, sortMode} = state;
    return {
        query,
        contentType,
        page,
        pageSize,
        searchMode,
        filters,
        deleteDirection,
        sortMode
    }
}

export const getFilters = (state: Store) => {
    return state.filters;
}

export const getSearchResult = (state: Store) => {
    const {searchResult, loading} = state;
    return {
        results: searchResult,
        loading: loading
    }
}