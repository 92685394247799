import { Box } from 'grommet';
import { Next, Previous } from 'grommet-icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getSearchParams, setPage, Store } from './data-store';
import { COLOR_BRAND, FONT_COLOR_DARK } from './styling/styles';

type PaginationProps = {
    totalResults: number
    display: number
}

export default function Pagination(props: PaginationProps){
    const {totalResults, display} = props;
    const {page, pageSize} = useSelector((state: Store) => getSearchParams(state));
    const totalPages = Math.ceil(totalResults/pageSize)
    const dispatch = useDispatch()
    const updatePage = (newPage: number) => dispatch(setPage(newPage))

    const _setPage = (_page: number) => {
        updatePage(_page)
    }

    let middle = page + 1;
    let left = middle - 1;
    let right = middle + 1;
    const pages = [<PageNumber key={middle} active={false} onClick={_setPage} value={middle}/>]
    const _display = Math.min(totalPages, display)

    while (right - left <= _display) {
        if (left > 0) {
            pages.unshift(
                <PageNumber key={left} active={true} onClick={_setPage} value={left}/>
            )
            left--;
        }
        if (right < totalPages + 1) { 
            pages.push(
                <PageNumber key={right} active={true} onClick={_setPage} value={right}/>
            )
            right++;
        }
    }
    
    return(
        <Box direction="row" justify="between" align="center" gap="small" flex="grow" pad="small" width="350px">
            <span>
                <First onClick={() => _setPage(0)} active={(page > 0)}/>
                <Back onClick={() => _setPage(page - 1)} data-active={(page > 0)}/>
            </span>
            <span>
                {pages}
            </span>
            <Forward onClick={() => _setPage(page + 1)} data-active={(page < (totalPages - 1))} />
        </Box>
    )
}

type PageNumberProps = {
    value: number;
    active: boolean;
    onClick: (page: number) => void;
}
function PageNumber({value, active, onClick: clickHandler}: PageNumberProps){
    return <Number active={active} onClick={() => clickHandler(value - 1)}>{value}</Number>
}

type FirstProps = {
    active: boolean;
    onClick: (page: number) => void;
}

function First(props: FirstProps) {
    const {active, onClick} = props
    return(
        <FirstButton active={active} onClick={() => onClick(0)}>
            <Previous />
            <Previous style={{transform: 'translate(-15px, 0)'}}/>
        </FirstButton>
    )
}

const FirstButton = styled.span<{active: boolean}>`
    display: inline-flex;
    width: 30px;
    pointer-events: ${p => p.active ? 'all' : 'none'};   
    & > * {
        stroke: ${p => p.active ? COLOR_BRAND : 'grey'};
    }
    &:hover {
        & > * {
            stroke: ${p => p.active ? 'white' : 'grey'};
            cursor: ${p => p.active ? 'pointer' : ''};
        }
    }
`

// Using data-attrs gets rid of the warnings for setting incorrect dom attributes
type HackProps = {
    'data-active': boolean
}

const Back = styled(Previous)<HackProps>`
    pointer-events: ${p => p['data-active'] ? 'all' : 'none'};   
    stroke: ${p => p['data-active'] ? COLOR_BRAND : 'grey'};
    &:hover {
        stroke: ${p => p['data-active'] ? 'white' : 'grey'};
        cursor: ${p => p['data-active'] ? 'pointer' : ''};    
    }
`
const Forward = styled(Next)<HackProps>`
    pointer-events: ${p => p['data-active'] ? 'all' : 'none'};   
    stroke: ${p => p['data-active'] ? COLOR_BRAND : 'grey'};
    &:hover {
        stroke: ${p => p['data-active'] ? 'white' : 'grey'};
        cursor: ${p => p['data-active'] ? 'pointer' : ''}; 
    }
`

const Number = styled.p<{active: boolean}>`
    display: inline;
    padding: 0px 5px;
    color: ${p => p.active ? COLOR_BRAND : FONT_COLOR_DARK};
    &:hover{
        text-decoration: ${p => p.active ? 'underline' : ''};
        cursor: ${p => p.active ? 'pointer' : ''};
    }
`