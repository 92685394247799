import { Anchor, Box } from 'grommet';
import React from 'react';
import styled from 'styled-components';
import { BookmarkButton } from '../../Bookmarks';
import LikeButton, { update_score } from '../../LikeButton';
import { Guideline } from '../SearchResults';


type Props = {
    document: Guideline;
    index: number;
}
export default function GuidelineCard(props: Props){
    const {document, index } = props
    const {_source: {url, title}} = document
    return(
        <div> 
            <Box direction="row" gap="small" align="center">
                <Box direction="column" justify="center" align="center" gap="medium" pad={{bottom: "small"}}>
                    <LikeButton
                        id={document._id}
                        index={document._index}
                        score={document._source.popularity_score}
                        direction='column'
                        liked={document._liked}
                        onLike={(delta) => update_score(document, delta)}
                    />
                    <BookmarkButton id={document._id} bookmarked={document._bookmarked}/>
                </Box> 
                <Box fill direction="column" gap="xsmall" margin={{bottom: "small"}} pad="small">
                    <Anchor size="large" color="brand" onClick={()=>openLink(url)}>
                        [{index + 1}] {title}
                    </Anchor>
                    <GuidelineSource onClick={()=>openLink(url)}>{url}</GuidelineSource>
                </Box>
            </Box>
        </div>
    )
}

function openLink(url: string){
    window.open(url)
}

export const GuidelineSource = styled(Anchor)`
    color: hsl(30, 40%, 55%);
    width: fit-content;
`