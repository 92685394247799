import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ContentDisplay from '../ContentDisplay';
import ImageDisplay from '../ImageDisplay'

type MatchParams = {
    id: string
}

export function Display({ match }: RouteComponentProps<MatchParams>){
    return(
        <ContentDisplay id={match.params.id}/>
    )
}

export function ImgDisplay({ match }: RouteComponentProps<MatchParams>){
    return(
        <ImageDisplay id={match.params.id}/>
    )
}