import React from 'react'
import { Text } from 'grommet';
import { MedscapeArticle } from '../MedscapeArticle';

export default function MedscapeArticleCard(document: MedscapeArticle){
    const {description, url, title} = document._source
    return{
            Heading: title,
            Source: url,
            Blurb: (
                <>
                    <Text>{description.substring(0,300)}...</Text>
                </>
            )
    }
}