import React, { useState, useEffect, useRef } from 'react'
import { Search } from 'grommet-icons'
import styled from 'styled-components'
import { BG_LAYER_0, COLOR_BRAND } from './styling/styles';
import { Box } from 'grommet';
import Autosuggest, { ChangeEvent } from 'react-autosuggest'
import { debounce } from 'throttle-debounce'
import './styling/index.css'
import { Result } from './results/SearchResults';
import { useSearch } from './util';
import { Store, getSearchParams, setQuery } from './data-store';
import { useSelector, useDispatch } from 'react-redux';

type SearchBarProps = {
    style?: React.CSSProperties
}

export default function SearchBar({ style }: SearchBarProps) {
    const searchHandler = useSearch();
    const {query: initialValue} = useSelector((state: Store) => getSearchParams(state))
    const [value, setValue] = useState(initialValue)
    const [suggestions, setSuggestions] = useState<string[] | never[]>([])
    const [index, setIndex] = useState(-1)
    const dispatch = useDispatch();
    const updateQuery = (newQuery: string) => dispatch(setQuery(newQuery))

    const _onSuggestionsFetchRequested = useRef<typeof onSuggestionsFetchRequested>(debounce(350, (value, setSuggestions, setIndex) => { onSuggestionsFetchRequested(value, setSuggestions, setIndex)})).current

    // this ensures that the query string stored in redux makes its way into the searchbar
    // when navigating directly to query page
    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])


    function renderSuggestion(suggestion: any){
        return (
            <div className="result">
                <div>{suggestion}</div>
            </div>
        )
    }

    function onChange(event: React.ChangeEvent<HTMLInputElement>, { newValue, method }: ChangeEvent){
        if (method === 'down') {
            index < suggestions.length - 1 && setValue(suggestions[index + 1])
            index < suggestions.length - 1 && setIndex(index + 1)
        }
        else if (method === 'up') {
            index > 0 && setValue(suggestions[index - 1])
            index > 0 && setIndex(index - 1)
        }
        else {
            setValue(newValue)
        }
        updateQuery(newValue)
    }

    function onSuggestionsClearRequested(){
        setSuggestions([])
    }
    
    function keyUpHandler(evt: React.KeyboardEvent<HTMLInputElement>){
        if (evt.key === 'Enter') {
            query()
        }
    }
    
    function onSuggestionSelected(event: React.FormEvent<any>, { suggestion }: any){
        setValue(suggestion)
        updateQuery(suggestion)
        query()
    }
    
    function query(){
        searchHandler()
    }

    const inputProps = {
        placeholder: '',
        value,
        onChange: onChange,
        onKeyUp: keyUpHandler,
    }
    return (
        <Bar style={{ ...style }} direction="row" border pad="6px" justify="start" align="center">
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={() => _onSuggestionsFetchRequested(value, setSuggestions, setIndex)}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={suggestion => suggestion}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected}
                inputProps={inputProps}
            />
            <Icon onClick={query} />
        </Bar>
    );
}

const onSuggestionsFetchRequested = (value: string, updateSuggestions: React.Dispatch<React.SetStateAction<string[]>>, updateIndex: React.Dispatch<React.SetStateAction<number>>) => {
/*    const fetchURL = 'http://34.121.54.251:9200/_search'
    let fetchBody = {
        query: {
            multi_match: {
                query: value,
                fields: ['title','description'],
                "operator": "and",
         	"fuzziness": 2,
                "prefix_length": 3,
                "minimum_should_match": "90%",
                "fuzzy_transpositions": false,
            }
        },
        sort: ['_score', { popularity_score: 'desc' }]
    }
    fetch(`${fetchURL}`, {
        method: 'POST',
        body: JSON.stringify(fetchBody),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.json())
    .then(out => {
        const resultsArray = out.hits.hits.map((h: Result) => h._index !== 'auntminnie' && h._source.title) as string[]
        const uniqueArray = [...Array.from(new Set(resultsArray))];
        updateSuggestions(uniqueArray)
        updateIndex(-1)
    })*/
}

const Icon = styled(Search)`
    position: relative;
    stroke: ${COLOR_BRAND};
    &:hover {
        stroke: white;
        cursor: pointer;    
    }
`

const Bar = styled(Box)`
    margin-left: 3%;
    min-height: 55px;
    background-color: ${BG_LAYER_0};
    box-sizing: border-box;
`
