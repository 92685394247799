import React, { useState, useEffect } from 'react';
import useSwr, { mutate } from 'swr';
import { AuxItem, getBookmarksURL, getPopularURL, getSystemSelectedURL, update_blacklist, update_bookmarks, getUID, ContentType, fetcherURL } from '../RemoteAPI';
import { DataType } from '../util';
import Fuse from 'fuse.js';
import { useSelector } from 'react-redux';
import { getContentType, Store } from '../data-store';

const RECENT_SORT = 'Most Recent' as const;
const OLDEST_SORT = 'Oldest' as const;
const A_Z_SORT = 'A to Z' as const;
const Z_A_SORT = 'Z to A' as const;

const sortList = [
    RECENT_SORT,
    OLDEST_SORT,
    A_Z_SORT,
    Z_A_SORT
] as const;

export const generateSortOptions = () => [...sortList]

export type SortOption = typeof sortList[number]



type SortableData = [
    AuxItem[],
    (id: string) => void,
    () => void,
    React.Dispatch<React.SetStateAction<SortOption>>,
    React.Dispatch<React.SetStateAction<string>>
]

export function useSortableData(type: DataType): any {
    let url = getUrl(type);
    const { data } = useSwr(url, fetcherURL)
    // console.log(data)
    const contentType = useSelector((state: Store) => getContentType(state))
    const [scratch, setScratch] = useState(data || []);
    const [sortKey, setSortKey] = useState<SortOption>(sortList[0]);
    const [filterString, setFilterString] = useState('');

    useEffect(() => {
        if (data) {
            const sorted = sort(sortKey, data, type)
            setScratch(sorted)
        }
    }, [data])

    useEffect(() => {
        const filtered = filter(filterString)
        if (filtered) {
            const typeFiltered = contentTypeFilter(contentType, filtered)
            const sorted = sort(sortKey, typeFiltered, type)
            setScratch(sorted)
        }
    }, [contentType, sortKey, filterString])

    const remove = (id: string) => {
        let filtered: any = []
        const d: any = data || [];
        filtered = d.filter((d:any) => d.id !== id)
        const removeFunction = removeRemote(type);
        removeFunction(id).then(resp => mutate(url, filtered))

    }

    const sort = (sortKey: SortOption, d: any, type: string) => {
        const result = sortItems(sortKey, d, type)

        return result;
    }

    const filter = (filterString: string) => {
        const options: Fuse.FuseOptions<{ title: string, type: string }> = {
            tokenize: true,
            matchAllTokens: true,
            threshold: 0.1,
            keys: ['title']
        };
        if (data && filterString !== '') {
            const fuse = new Fuse(data, options)
            const result = fuse.search(filterString)
            if (result) {
                setScratch(result)
                return result
            }
            return data
        }
        return data;
    }

    const restore = () => {
        setScratch(data || []);
    }

    return [scratch, remove, restore, setSortKey, setFilterString]

}

function getUrl(type: DataType) {
    switch (type) {
        case 'bookmark': return getBookmarksURL;
        case 'popular': return getPopularURL;
        case 'recommended': return getSystemSelectedURL;
        default: return ''
    }
}

function removeRemote(type: DataType) {
    switch (type) {
        case 'popular':
        case 'recommended': {
            return update_blacklist
            // break;
        }
        case 'bookmark': {
            return update_bookmarks
            // break;
        }
        default: {
            console.log('trying to remove invalid type')
            throw Error('trying to remove invalid type')
        }
    }
}

function sortItems(sortKey: SortOption, items: any, type: string) {

        let content = [...items]
        switch (sortKey) {
            case OLDEST_SORT:
                content.sort((a, b) => {
                    if (a.published > b.published) { return 1 }
                    else { return -1 }
                })
                break;
            case RECENT_SORT:
                content.sort((a, b) => {
                    if (a.published <= b.published) { return 1 }
                    else { return -1 }
                })
                break;
            case A_Z_SORT:
                content.sort((a, b) => { return (a.title).localeCompare(b.title); })
                break;
            case Z_A_SORT:
                content.sort((a, b) => { return (b.title).localeCompare(a.title); })
                break;
            default:
        }
        return content
    }

// }

function contentTypeFilter(type: ContentType, items: AuxItem[]) {
    switch (type) {
        case 'article': return items.filter(item => item.type === 'article');
        case 'case': return items.filter(item => item.type === 'case');
        case 'guideline': return items.filter(item => item.type === 'guideline');
        case 'images': return items.filter(item => item.type === 'images');
        default: return items;
    }
}
