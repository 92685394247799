import { Anchor, Box, Grid, Layer } from 'grommet';
import { Close, Next, Previous } from 'grommet-icons';
import React from 'react';
import Magnifier from 'react-magnifier';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { getContentType, getSearchResult, Store } from './data-store';
import Header from './Header';
import Loading from './Loading';
import { fetchByID } from './RemoteAPI';
import { Case } from './results/cases/Case';
import './styling/index.css';
import { COLOR_BRAND } from './styling/styles';

export default function ImageDisplay({ id }: { id: string }) {
    const location = useLocation();
    const history = useHistory();

    const { results } = useSelector((state: Store) => getSearchResult(state));
    const items = results ? results.results : [];

    function next() {
        const document = items[location.state.index + 1]
        history.push(`/image/${document._id}`, { background: location.state.background, index: location.state.index + 1, document })
    }

    function prev() {
        const document = items[location.state.index - 1]
        history.push(`/image/${document._id}`, { background: location.state.background, index: location.state.index - 1, document })
    }

    async function fetchDocument(_id: string) {
        try {
            const document = await fetchByID(_id)
            const state = location.state
            Object.entries(document).length > 0 && history.replace(location.pathname, { ...state, document: document })
        }
        catch (err) {
            console.error(err)
        }
    }

    (!location.state || !location.state.document) && fetchDocument(id)
    return (
        !location.state ?
            <div>
                <Header />
                <Box fill justify="center" align="center">
                    <Loading size="300px" />
                </Box>
            </div> :
            location.state.background ?
                <Layer full margin="large" onClickOutside={() => history.push(`${location.state.background.pathname}${location.state.background.search}`)} animation="none">
                    {!location.state.document ? <Box fill justify="center" align="center"> <Loading size="300px" /> </Box> :
                        <Display
                            document={location.state.document}
                            onClose={() => history.push(`${location.state.background.pathname}${location.state.background.search}`)}
                            onNext={location.state.index < items.length - 1 ? next : undefined}
                            // onNext={location.state.index < items.length - 1 ? next : () => dispatch(setPage(2))}

                            onPrev={location.state.index > 0 ? prev : undefined}
                        />}
                </Layer> :
                <div style={{ width: "100%", height: "100%" }}>
                    <Header />
                    {!location.state.document ? <Box fill justify="center" align="center"> <Loading size="300px" /> </Box> :
                        <Display document={location.state.document} onClose={() => history.push('/')} />}
                </div>
    )
}

export type CaseProps = {
    document: Case;
    onClose: () => void;
    onPrev?: () => void;
    onNext?: () => void;
    onKnowledgeEnable?: () => void;
}

function Display(props: CaseProps) {
    const { document, onClose, onPrev, onNext } = props

    const history = useHistory();
    const location = useLocation();
    const contentType = useSelector((state: Store) => getContentType(state));

    return (
        <Grid
            fill
            areas={[
                { name: "top", start: [0, 0], end: [1, 0] },
                { name: "bottom", start: [0, 1], end: [1, 1] },
            ]}
            columns={["flex"]}
            rows={["77px", "flex"]}
        >
            <Box gridArea="top" direction="row" justify="between" align="center" pad="medium">
                {contentType === 'images' ?
                    <span>
                        <PrevImage onClick={onPrev} enable={typeof onPrev !== 'undefined'} />
                        <NextImage onClick={onNext} enable={typeof onNext !== 'undefined'} />
                    </span> : <span> </span>}
                <StyledAnchor
                    onClick={() => history.push(`/content/${document._id}`, {
                        background: location.state.background,
                        index: location.state.index,
                    })}
                >
                    Open Case
                </StyledAnchor>
                <CloseImage onClick={onClose} />
            </Box>
            <Box direction="column" gridArea="bottom" overflow={{ horizontal: 'hidden', vertical: 'auto' }} gap="small" pad={{ vertical: 'small', horizontal: 'medium' }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    {/* <img
                        src={document._source.image}
                        style={{ maxHeight: "90%" }}
                    /> */}
                    <span >
                        {document._index === 'auntminnie' ? <Magnifier src={`https://${document._source.image}`} zoomImgSrc={`https://${document._source.image}`} mgShape="square" mgWidth={185} zoomFactor={1.25} mgHeight={185} mgShowOverflow={false} />
                            : <Magnifier src={document._source.image} zoomImgSrc={document._source.image} mgShape="square" mgWidth={185} zoomFactor={1.25} mgHeight={185} mgShowOverflow={false} />}
                    </span>
                </div>
            </Box>
        </Grid>
    )
}

const StyledAnchor = styled(Anchor)`
-webkit-user-select: none;
-moz-user-select: none;
-khtml-user-select: none;
-ms-user-select: none;
`

const CloseImage = styled(Close)`
    height: 29px;
    stroke: ${COLOR_BRAND};
    &:hover {
        stroke: white;
        cursor: pointer;    
    }
`
const PrevImage = styled(Previous) <{ enable: boolean }>`
    height: 29px;
    stroke: ${p => p.enable ? COLOR_BRAND : 'grey'};
    &:hover {
        stroke: ${p => p.enable ? 'white' : 'grey'};
        cursor: ${p => p.enable ? 'pointer' : ''};    
    }
`
const NextImage = styled(Next) <{ enable: boolean }>`
    height: 29px;
    stroke: ${p => p.enable ? COLOR_BRAND : 'grey'};
    &:hover {
        stroke: ${p => p.enable ? 'white' : 'grey'};
        cursor: ${p => p.enable ? 'pointer' : ''};    
    }
`