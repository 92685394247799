import { Text } from "grommet";
import React from 'react';
import { BaseArticle, Row, Title } from "./Article";
import { Header } from '../Card';

export interface MedscapeArticle extends BaseArticle {
    _index: 'medscape' | 'medscape_ed';
    _source: {
        authors: string;
        popularity_score: number;
        content: string[];
        date: string;
        description: string;
        image: string;
        keywords?: string[];
        similar?: string[];
        title: string;
        url: string;
    }
}

export default function Medscape(document: MedscapeArticle){
    const {
        authors,
        content,
        date,
        description,
        image,
        keywords,
        similar,
        title,
        url,
    } = document._source
    return {
        Left: ( 
            <>
                <Title text={title} url={url}/>
                <Header>{authors}</Header>
                <Text margin="xsmall">{description}</Text>
                <Text margin="xsmall">{content}</Text>
                <div style={{alignSelf: 'center', marginTop: '24px'}}><img alt={description} src={image} style={{width: '500px', height: '500px'}}/></div>
            </>
        ),
        Right: (
            <>
                <Row label="Author(s):" field={authors}/>
                <Row label="Date:" field={date}/> 
                {keywords && <Row label="Keywords:" field={KeywordArray(keywords)} />}
                {similar && <Row label="Similar:" field={SimilarArray(similar)} />}
            </>
        ),
        Title: title
    }
}

function SimilarArray(similar: string[]){
    return <>{similar.map(Similar)}</> 
}

function Similar(similar: string, index: number, similar_arr: string[]){
    const length = similar_arr.length
    return (
        <>
            <Text key={index} size="medium">{similar}</Text>
            <Text size="medium">{index < (length - 1) && ','}&nbsp;</Text>   
        </>
    )
}

function KeywordArray(keywords: string[]){
    return <>{keywords.map(Keyword)}</> 
}

function Keyword(keyword: string, index: number, keywords: string[]){
    const length = keywords.length
    return (
        <>
            <Text key={index} size="medium">{keyword}</Text>
            <Text size="medium">{index < (length - 1) && ','}&nbsp;</Text>   
        </>
    )
}