import { Text } from "grommet";
import React from 'react';
import { BaseArticle, Row, Title } from "./Article";
import { Header } from '../Card';

export interface GscholarArticle extends BaseArticle {
    _index: 'gscholar';
    _source: {
        authors: string[];
        popularity_score: number;
        citations: number;
        description: string[];
        title: string[];
        url: string;
    }
}

export default function Gscholar(document: GscholarArticle){
    const {
        authors,
        citations,
        description,
        title,
        url,
    } = document._source
    return {
        Left: ( 
            <>
                <Title text={title.join()} url={url}/>
                <Header>{AuthorArray(authors)}</Header>
                <Text margin="xsmall">{description}</Text>
            </>
        ),
        Right: (
            <>
                <Row label="Author(s):" field={AuthorArray(authors)}/>
                <Row label="Citations:" field={citations} />
            </>
        ),
        Title: title[0]
    }
}

function AuthorArray(authors: string[]){
    return <>{authors.map(Author)}</> 
}

function Author(author: string, index: number, authors: string[]){
    const length = authors.length
    return (
        <>
            <Text key={index} size="medium" alignSelf="end">{author}</Text>
            <Text size="medium">{index < (length - 1) && ','}&nbsp;</Text>   
        </>
    )
}
