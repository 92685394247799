import React from 'react'
import { Text } from 'grommet';
import { PubmedArticle } from '../PubmedArticle';

export default function PubmedArticleCard(document: PubmedArticle){
    const {description, url, title} = document._source
    return{
            Heading: title,
            Source: url,
            Blurb: (
                <>
                    {description && <Text>{description.substring(0,300)}...</Text>}
                </>
            )
    }
}