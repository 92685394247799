import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Select } from "grommet";
import { Guidelines } from './App'
import { useSearch } from './util';
import { setQuery, setContentType } from './data-store';
import { ContentType } from './RemoteAPI';
import { useDispatch } from 'react-redux';

// document.querySelector("#root > div > div > div.StyledBox-sc-13pk1d4-0.hyyuNU > div.StyledTabs-a4fwxl-2.jBsJwq.StyledBox-sc-13pk1d4-0.IRSNj > div.StyledTabs__StyledTabsHeader-a4fwxl-0.cjlqAH.StyledBox-sc-13pk1d4-0.eIhhEi > button.Select__StyledSelectDropButton-sc-17idtfo-1.cvpccN.StyledButton-sc-323bzc-0.fiZNOt > div > div.StyledBox-sc-13pk1d4-0.eLQtUu > svg")

export default function Dropdown() {
    const searchHandler = useSearch()
    const dispatch = useDispatch()
    const [option, setOption] = useState('')
    const updateQuery = (newQuery: string) => dispatch(setQuery(newQuery))
    const updateContentType = (newContentType: ContentType) => dispatch(setContentType(newContentType))
    
    // Can't depend on query because that gets changed every keystroke. This is a hack that could be fixed?
    useEffect(() => {
        option !== '' && searchHandler()
    }, [option])

    function onGuidelineChange(option : Guidelines) {
        updateQuery(option)
        updateContentType('guideline')
        setOption(option)
    }

    return (
        <StyledContainer>
            <Select 
                plain={true}
                margin={'-20px'}
                options={guidelinesArr}
                value={<React.Fragment/>}
                onChange={({ option }) => onGuidelineChange(option)}
            />
        </StyledContainer>
    )
}

const StyledContainer = styled.span`
    & > button{
        box-shadow: none;
    }
`

const guidelinesArr= ['Bi-RADS', 'C-RADS', 'CAD-RADS', 'LI-RADS', 'Lung-RADS', 'Ni-RADS', 'O-RADS', 'PI-RADS', 'TI-RADS' ]
