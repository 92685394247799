import React, { useEffect, useState } from 'react';
import styled, { keyframes } from "styled-components";

export default function Loading({size}: {size: string}) {
  const [enable, setEnable] = useState(false);  
    useEffect(() => {
      const id = setTimeout(() => {
        setEnable(true);
      }, 200);
      return () => clearTimeout(id)
    }, []); 
    return (
      <div style={{height: "100%"}}>
        {enable && <Spinner>
          <Rect1 />
          <Rect2 />
          <Rect3 />
          <Rect4 />
          <Rect5 />
        </Spinner>}
      </div>
    )
  
}

const stretchDelay = keyframes`
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.6);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
`;

const Spinner = styled.div`
  margin: 100px auto;
  height: 150px;
  text-align: center;
  & > div {
    background-color: #007BFF;
    height: 100%;
    width: 10px;
    display: inline-block;
    margin: 0 4px;
  }
`;

const Rect1 = styled(Spinner)`
  animation: ${stretchDelay} 1.2s -1.2s infinite ease-in-out; 
`

const Rect2 = styled(Spinner)`
  animation: ${stretchDelay} 1.2s -1.1s infinite ease-in-out;
`

const Rect3 = styled(Spinner)`
  animation: ${stretchDelay} 1.2s -1.0s infinite ease-in-out;
`

const Rect4 = styled(Spinner)`
  animation: ${stretchDelay} 1.2s -0.9s infinite ease-in-out;
`

const Rect5 = styled(Spinner)`
  animation: ${stretchDelay} 1.2s -0.8s infinite ease-in-out;
`