import { Accordion, AccordionPanel, Anchor, Box, Button, CheckBox, Heading, RangeInput } from 'grommet';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getFilters, getPageSize, getSearchMode, getAuntminnieAuth, setFilter, setPageSize, setSearchMode, Store } from '../data-store';
import { clearBlacklist, SearchMode } from '../RemoteAPI';
import ToggleSwitch from '../styling/ToggleSwitch';
import { useSearch } from '../util';


export type Filters = {
    categories: Catagory[]
    datasources: Datasource[]
    modality: Modality[]
}

interface CheckboxBundleProps {
    changeHandler: (key: keyof Filters, checked: string[]) => void
    header: string
    items: readonly string[]
    type: keyof Filters
    includeIndeterminate?: boolean
    fitContent?: boolean
}

const getChecked = (filters: Filters, type: keyof Filters) => {
    switch (type) {
        case 'modality': return filters[type]
        case 'categories': return filters[type]
        case 'datasources': return filters[type].map(d => datasourceMap_reverse[d])
        default: return []
    }
}

export function CheckboxBundle(props: CheckboxBundleProps) {
    const {
        changeHandler,
        header,
        items,
        type,
        includeIndeterminate,
        fitContent
    } = props
    const auntminnieAuth = useSelector((state: Store) => getAuntminnieAuth(state))
    const filters = useSelector((state: Store) => getFilters(state))
    const _checked = getChecked(filters, type)
    const style = fitContent ? { width: 'fitContent ' } : {}
    
    function checkAll() {
        const toCheck = _checked.length === items.length ? [] : [...items]
        changeHandler(props.type, toCheck)
    }

    function check(val: typeof _checked[0]) {
        const toCheck = [..._checked]
        const idx = _checked.indexOf(val)
        if (_checked.includes(val)) {
            toCheck.splice(idx, 1)
        }
        else {
            toCheck.push(val)
        }
        changeHandler(props.type, toCheck)
    }

    return (
        <Accordion animate>
            <AccordionPanel label={header}>
                <Box direction="column" pad={{ vertical: "xxsmall" }} gap="xsmall" flex="grow">
                    {includeIndeterminate &&
                        <span style={style}>
                            <CheckBox
                                checked={_checked.length === items.length}
                                indeterminate={_checked.length > 0 && _checked.length < items.length}
                                label="All"
                                onChange={checkAll}
                            />
                        </span>
                    }
                    {items.map((item, idx) => {
                        return (
                            <span style={style} key={idx}>
                                <CheckBox
                                    checked={_checked.includes(item)}
                                    label={item}
                                    onChange={() => check(item)}
                                    disabled={item === 'AuntMinnie.com' && !auntminnieAuth}
                                />
                            </span>
                        )
                    })}
                </Box>
            </AccordionPanel>
        </Accordion>
    )
}

interface AnchorBundleProps {
    header?: string
    items: string[]
    fitContent?: boolean
    clickHandlers: ((...args: any[]) => any)[]
}
export function AnchorBundle(props: AnchorBundleProps) {
    const style = props.fitContent ? { width: 'fit-content' } : {}
    return (
        <Box direction="column" pad={{ vertical: "xxsmall" }} gap="xsmall" flex="grow">
            <Heading level='3' size="small" margin={{ vertical: "small" }}>{props.header}</Heading>
            {props.items.map((item, idx) => {
                return (
                    <span style={style} key={idx}>
                        <Anchor
                            onClick={() => props.clickHandlers[idx](item)}
                            color="brand"
                        >
                            {item}
                        </Anchor>
                    </span>
                )
            })}
        </Box>
    )
}

export interface MenuProps {
    logout: () => void;
    bookmarkChecked: boolean
    onBookmarkCheck: () => void;
    setOpen: (x: boolean) => void;
    setMenuBurger: (x: boolean) => void;

}
export function MenuBase(props: MenuProps) {
    const { logout, bookmarkChecked, onBookmarkCheck, setOpen, setMenuBurger } = props
    const history = useHistory()
    const status = localStorage.getItem('status')

    const handleSilentLogout=() => {
        // console.log('here')
        window.open(' ','_parent','');
        window.close();
    }

    return (
        <div>
            {/* <Box pad={{ bottom: "small" }}>
                <Anchor onClick={() => {
                    history.push(`/`);
                    setOpen(false)
                }}>
                    Home</Anchor>
            </Box> */}

            <Box pad={{ bottom: "small" }}>
                <Anchor onClick={() => {
                    history.push(`/userprofile`);
                    setOpen(false)
                    setMenuBurger(false)
                }}>
                    User Profile</Anchor>
            </Box>

            <Anchor onClick={() => window.open('https://www.realtimemedical.com')}>About Us</Anchor>
            <Accordion animate>
                <AccordionPanel label="Contact">
                    <p>
                        sales@realtimemedical.com
                        <br />
                        info@realtimemedical.com
                    </p>
                    <p>Tel: (888) 590-7662</p>
                    <p>7111 Syntex Drive, 3rd Floor, Mississauga, Ontario, L5N 8C3, Canada</p>
                </AccordionPanel>
                <AccordionPanel label="Support">
                    <p>support@realtimemedical.com</p>
                    <p>Tel: (888) 590-7662</p>
                </AccordionPanel>
            </Accordion>
            <Box direction="row" align="center" gap="small" margin={{ vertical: "small" }}>
                <ToggleSwitch state={bookmarkChecked} onClick={onBookmarkCheck} />
                <Heading level={4} size="xsmall" style={{ display: "inline-block" }}>Bookmarks {bookmarkChecked ? "On" : "Off"}</Heading>
            </Box>
            <Box align="center">
                <Button
                    onClick={status === 'SSO'? handleSilentLogout: logout}
                    margin={{ vertical: "small" }}
                    style={{ width: "fit-content" }}
                    label="Logout"
                    primary
                />
            {/* {status ==='SSO' &&        window.close() } */}
            </Box>
        </div>
    )
}

export interface FilterMenuProps extends MenuProps { }

type FilterKeys = keyof Filters

export function FilterMenu() {
    const filters = useSelector((state: Store) => getFilters(state));
    const searchMode = useSelector((state: Store) => getSearchMode(state))
    const dispatch = useDispatch();
    const updateFilter = (newFilters: Filters) => dispatch(setFilter(newFilters))
    const updateSearchMode = (newMode: SearchMode) => dispatch(setSearchMode(newMode))

    const searchHandler = useSearch();

    function onCheck<K extends FilterKeys>(key: K, checked: string[], map?: { [key: string]: string }) {
        const newFilters = { ...filters };
        if (map) {
            const tmp = [...checked]
            checked = tmp.map(item => map[item]) as Filters[K]
        }
        newFilters[key] = checked as Filters[K];
        updateFilter(newFilters)
    }

    function onSearchModeToggle() {
        switch (searchMode) {
            case 'exact':
                return updateSearchMode('fuzzy')
            case 'fuzzy':
                return updateSearchMode('exact')
        }
    }

    // function onDeleteDirectionToggle() {
    //     switch (deleteDirection) {
    //         case 'left':
    //             return updateDeleteDirection('right')
    //         case 'right':
    //             return updateDeleteDirection('left')
    //     }
    // }

    // function onSortModeToggle() {
    //     switch (sortMode) {
    //         case 'elastic':
    //             return updateSortMode('completeness')
    //         case 'completeness':
    //             return updateSortMode('elastic')
    //     }
    // }

    return (
        <div>
            <Box margin={{ top: "medium" }} gap="xsmall" flex="grow">Select items to narrow query</Box>
            {/* <CheckboxBundle 
                key="cat" 
                header="Body Parts and Specialities" 
                items={categoryList} 
                fitContent 
                type="categories" 
                changeHandler={onCheck}
            /> */}
            <CheckboxBundle
                key="ds"
                header="Data Sources"
                items={Object.keys(datasourceMap)}
                fitContent
                type="datasources"
                changeHandler={(key, checked) => onCheck(key, checked, datasourceMap)}
            />
            <CheckboxBundle
                key="modality"
                header="Modality"
                items={tagList}
                fitContent
                type="modality"
                changeHandler={onCheck}
            />
            <PageSizeSelection />
            <Box direction="row" align="center" gap="small" margin={{ vertical: "small" }}>
                <ToggleSwitch state={searchMode === 'exact'} onClick={onSearchModeToggle} />
                <Heading level={4} size="xsmall" style={{ display: "inline-block" }}>Exact Word Match: {searchMode === 'exact' ? "On" : "Off"}</Heading>
            </Box>
            <Box direction="row" gap="small" margin={{ vertical: "small" }}>
                <Button
                    onClick={searchHandler}
                    margin={{ vertical: "small" }}
                    style={{ width: "50%", padding: "2px" }}
                    label="Apply Changes"
                    primary

                />
                <Button
                    onClick={() => updateFilter({ categories: [], datasources: [], modality: [] })}
                    margin={{ vertical: "small" }}
                    style={{ width: "50%", padding: "2px" }}
                    label="Clear All"
                    primary
                />
            </Box>
            {/* <Box direction="row" align="center" gap="small" margin={{vertical: "small"}}>
                <ToggleSwitch state={deleteDirection==='right'} disabled={searchMode==='exact'} onClick={onDeleteDirectionToggle} monocolor/>
                <Heading level={4} size="xsmall" style={{display: "inline-block", color: `${searchMode==='exact' ? '#656565' : 'white'}`}}>Search Delete Direction: {deleteDirection === 'left' ? "Left" : "Right"}</Heading>
            </Box>
            <Box direction="row" align="center" gap="small" margin={{vertical: "small"}}>
                <ToggleSwitch state={sortMode==='completeness'} disabled={searchMode==='exact'} onClick={onSortModeToggle}/>
                <Heading level={4} size="xsmall" style={{display: "inline-block", color: `${searchMode==='exact' ? '#656565' : 'white'}`}}>Sort Based on Search Completeness: {sortMode === 'completeness' ? "On" : "Off"}</Heading>
            </Box> */}
            {process.env.NODE_ENV === 'development' && <Anchor
                onClick={clearBlacklist}
                as="div"
                margin={{ vertical: "small" }}
                style={{ width: "fit-content" }}
            >
                Clear Blacklist
            </Anchor>}
        </div>
    )
}

function PageSizeSelection() {
    const pageSize = useSelector((state: Store) => getPageSize(state))
    const dispatch = useDispatch();
    const onChange = (size: number) => dispatch(setPageSize(size))

    function updatePageSize(evt: React.ChangeEvent<HTMLInputElement>) {
        const size = +evt.currentTarget.value
        // setPageSize(size)
        onChange(size)
    }

    return (
        <Box direction="column" pad={{ vertical: "xxsmall" }} gap="xsmall" flex="grow">
            <Heading
                level='4'
                size="small"
                margin={{ vertical: "small" }}
            >
                Page Size: {pageSize}
            </Heading>
            <RangeInput
                min={5}
                max={50}
                step={5}
                value={pageSize}
                onChange={updatePageSize}
            />
        </Box>
    )
}

export type MenuBundle = React.ReactElement<CheckboxBundleProps> | React.ReactElement<AnchorBundleProps>
export type MenuType = React.ReactElement<MenuProps> | React.ReactElement<FilterMenuProps>

const categoryList = [
    'Breast', 'Cardiac', 'Central Nervous', 'Chest', 'Forensic', 'Gastrointestinal', 'Gynecology',
    'Haematology', 'Head & Neck', 'Hepatobilary', 'Interventional', 'Musculoskeletal',
    'Obstetrics', 'Oncology', 'Paediatrics', 'Spine', 'Trauma', 'Urogenital', 'Vascular'
] as const;

type Catagory = typeof categoryList[number];

export const datasourceMap = {
    'AuntMinnie.com': 'auntminnie',
    'Radiopaedia.org Cases': 'radiopedia',
    'Radiopaedia.org Articles': 'radiopedia_article',
    'PubMed': 'pubmed',
    'Medscape.org': 'medscape',
    'Medscape Education': 'medscape_ed'
} as const;

const datasourceList = Object.values(datasourceMap);
export const datasourceName = Object.keys(datasourceMap);

type Datasource = typeof datasourceList[number];

const datasourceMap_reverse: { [key: string]: string } = {
    'auntminnie': 'AuntMinnie.com',
    'radiopedia': 'Radiopaedia.org Cases',
    'radiopedia_article': 'Radiopaedia.org Articles',
    'pubmed': 'PubMed',
    'medscape': 'Medscape.org',
    'medscape_ed': 'Medscape Education'
}

const tagList = [
    'Fluoroscopy', 'X-ray', 'CT', 'Ultrasound', 'MRI', 'Barium', 'Nuclear medicine', 'Pathology', 'Mammography', 'DSA (angiography)', 'Photo', 'Diagram', 'Annotated image'
] as const;

type Modality = typeof tagList[number]

// const misc = [
//     'About Us', 'Contact', 'Support'
// ]

// const StyledLink = styled(Link)`
//     text-decoration: none;

//     &:focus, &:hover, &:visited, &:link, &:active {
//         text-decoration: none;
// }
// `