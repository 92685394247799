import { Heading, Text } from 'grommet';
import React from 'react';
import Magnifier from 'react-magnifier';
import { BaseCase, Frame, Row, Title } from './Case';

export interface AuntMinnieCase extends BaseCase {
    _index: 'auntminnie'
    _source: {
        authors: string;
        popularity_score: number;
        url: string;
        description: string;
        image: string;
        date: string;
        tags: string | null;
    }
}

export default function Auntminnie(document: AuntMinnieCase) {
    const {
        url,
        authors,
        description: text,
        image: image_src,
        date: publication_date,
        tags,
    } = document._source
    const index = document._index
    return {
        Left: (
            <>
                <Title text={text} url={url} index={index} />
                <Heading level="4">{authors}</Heading>
                <Text margin="xsmall">{text}</Text>
                <Frame>                   
                    <Magnifier src={`https://${image_src}`} zoomImgSrc={`https://${image_src}`} mgShape="square" mgWidth={185} zoomFactor={1.25} mgHeight={185} mgShowOverflow={false} />
                </Frame>
            </>
        ),
        Right: (
            <>
                <Row label="Author(s):" field={authors} />
                <Row label="Date:" field={publication_date} />
                {tags && <Row label="Tags" field={<TagArray tags={tags.replace('Tags:', '').split(',')} />} />}
            </>
        ),
        Title: text
    }
}

function TagArray({ tags }: { tags: string[] }) {
    return <>{tags.map(Tag)}</>
}

function Tag(tag: string, index: number, tags: string[]) {
    const length = tags.length
    return (
        <>
            <Text key={index} size="medium">{tag}</Text>
            <Text size="medium">{index < (length - 1) && ','}&nbsp;</Text>
        </>
    )
}