import { Box, Tab, Tabs, Text } from 'grommet';
import { parse } from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import {
    getQuery,
    getSearchParams,
    setFilter,
    getSearchResult,
    getAuntminnieAuth,
    SearchParams,
    setContentType,
    setPage,
    setResults,
    setSearchParams,
    Store,
} from '../data-store';
import Dropdown from '../Dropdown';
import { sanitizedParams } from '../pages/query';
import { ContentType, fetchContent } from '../RemoteAPI';
import { useSearch } from '../util';
import ArticleDisplay, { Article } from './articles/Article';
import ArticleCard from './articles/cards/ArticleCard';
import CaseCard from './cases/cards/CaseCard';
import CaseDisplay, { Case } from './cases/Case';
import {
    AllResults,
    Articles,
    Cases,
    Guidelines,
    Images,
} from './search-layout';
import { Filters } from '../menu/MenuBundles'

export interface Document {
    _id: string;
    _index: string;
    _score: number;
    _source: {
        description?: string;
        discussion?: string;
        content?: string;
    };
    _type: 'article' | 'case' | 'guideline';
    _liked: boolean;
    _bookmarked: boolean;
}

export interface Guideline extends Document {
    _index: 'guidelines';
    _source: {
        category: string;
        keywords: string[];
        title: string;
        url: string;
        popularity_score: number;
        description?: string;
    };
    _type: 'guideline';
}

export type Result = Article | Case | Guideline;

export type Results = Result[];

export type SearchResult = {
    total_results: number;
    results: Results;
};

// function usePrevious(value: string) {
//     const ref = useRef<string>();
//     useEffect(() => {
//       ref.current = value;
//     });
//     return ref.current;
//   }

export function SearchResults() {
    const { results: result, loading } = useSelector((state: Store) =>
        getSearchResult(state)
    );
    const dispatch = useDispatch();
    const updatePage = (newPage: number) => dispatch(setPage(newPage));
    const updateContentType = (newContentType: ContentType) =>
        dispatch(setContentType(newContentType));
    const updateSearchParams = (newParams: SearchParams) =>
        dispatch(setSearchParams(newParams));
    const storeQuery = useSelector((state: Store) => getQuery(state));

    const searchHandler = useSearch();
    const auntminnieAuth = useSelector((state: Store) => getAuntminnieAuth(state))
    const updateFilter = (newFilters: Filters) => dispatch(setFilter(newFilters))
    const location = useLocation();
    const params = useSelector((state: Store) => getSearchParams(state));
    const { contentType, page } = params;
    const isSafari =
        /Safari/.test(navigator.userAgent) &&
        /Apple Computer/.test(navigator.vendor);
    const tab = tabFromContentType[contentType];

    useEffect(() => {
        const parsed = parse(location.search, { parseNumbers: true });
        const {
            _page,
            _size,
            _query,
            _contentType,
            _filters,
            _mode,
            _direction,
            _sortMode,
        } = sanitizedParams(parsed);
        updateSearchParams({
            query: storeQuery,
            contentType: _contentType,
            pageSize: _size,
            page: _page,
            filters: _filters,
            searchMode: _mode,
            deleteDirection: _direction,
            sortMode: _sortMode,
        });

        fetchContent({
            query: _query,
            page: _page,
            size: _size,
            category: _filters.categories,
            source: _filters.datasources,
            modality: _filters.modality,
            searchMode: _mode,
            deleteDirection: _direction,
            sortMode: _sortMode,
            type: _contentType,
        }).then((result) => {
            location.state = { ...location.state, results: result.results };
            dispatch(setResults(result));
        });
    }, [location.search]);

    useEffect(() => searchHandler(), [page, contentType]);

    function updateTab(newTab: TabEnum) {
        requestPage(0, newTab);
    }
    function requestPage(page: number, _tab?: TabEnum) {
        const selectedTab = typeof _tab !== 'undefined' ? _tab : tab;
        const contentType = contentTypeFromTab[selectedTab];
        updatePage(page);
        updateContentType(contentType);
    }

    return (
        <>
            {isSafari ? (
                <Box
                    fill
                    pad={{ top: 'medium', left: 'medium' }}
                    overflow="auto"
                >
                    <Tabs
                        justify="start"
                        activeIndex={tab}
                        onActive={updateTab}
                    >
                        <Tab title="All Results">
                            <AllResults result={result} loading={loading} />
                        </Tab>
                        <Tab title="Articles">
                            <Articles result={result} loading={loading} />
                        </Tab>
                        <Tab title="Cases">
                            <Cases result={result} loading={loading} />
                        </Tab>
                        <Tab title="Images">
                            <Images result={result} loading={loading} />
                        </Tab>
                        <Tab title="Guidelines">
                            <Guidelines result={result} loading={loading} />
                        </Tab>
                        <Dropdown />
                    </Tabs>
                </Box>
            ) : (
                <Box fill pad={{ top: 'medium', left: 'medium' }}>
                    <Tabs
                        justify="start"
                        activeIndex={tab}
                        onActive={updateTab}
                    >
                        <Tab title="All Results">
                            <AllResults result={result} loading={loading} />
                        </Tab>
                        <Tab title="Articles">
                            <Articles result={result} loading={loading} />
                        </Tab>
                        <Tab title="Cases">
                            <Cases result={result} loading={loading} />
                        </Tab>
                        <Tab title="Images">
                            <Images result={result} loading={loading} />
                        </Tab>
                        <Tab title="Guidelines">
                            <Guidelines result={result} loading={loading} />
                        </Tab>
                        <Dropdown />
                    </Tabs>
                </Box>
            )}
        </>
    );
}

type CardProps = {
    document: Result;
    index: number;
};
export function Card(props: CardProps) {
    const { document, index } = props;
    switch (document._type) {
        case 'article':
            return <ArticleCard document={document} index={index} />;
        case 'case':
            return <CaseCard document={document} index={index} />;
        case 'guideline':
            return <></>;
        default: 
            return null
    }
}

type DisplaySwitchProps = {
    selected: Result;
    onClose: () => void;
    onPrev?: () => void;
    onNext?: () => void;
};
export function DisplaySwitch(props: DisplaySwitchProps) {
    const { selected, onClose, onPrev, onNext } = props;
    switch (selected._type) {
        case 'article':
            return (
                <ArticleDisplay
                    document={selected}
                    onClose={onClose}
                    onNext={onNext}
                    onPrev={onPrev}
                />
            );
        case 'case':
            return (
                <CaseDisplay
                    document={selected}
                    onClose={onClose}
                    onNext={onNext}
                    onPrev={onPrev}
                />
            );
        case 'guideline':
            return <></>;
        default: return null
    }
}

export enum TabEnum {
    All,
    Articles,
    Cases,
    Images,
    Guidelines,
}

export const contentTypeFromTab: { [tab in TabEnum]: ContentType } = {
    0: 'mixed',
    1: 'article',
    2: 'case',
    3: 'images',
    4: 'guideline',
};

export const tabFromContentType: { [contentType in ContentType]: TabEnum } = {
    mixed: 0,
    article: 1,
    case: 2,
    images: 3,
    guideline: 4,
};

export const ArticleHeader = styled(Text)``;
