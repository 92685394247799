import { Box, Grommet } from 'grommet';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { getHomeType, Store } from './data-store';
import Sidebar from './menu/Sidebar';
import { ContinuousLearning } from './pages/continuous-learning';
import { Display, ImgDisplay } from './pages/display';
import { Query } from './pages/query';
import { ReadingSession } from './pages/reading-session';
import './styling/App.css';
import { BG_LAYER_1, COLOR_BRAND, FONT_COLOR_DARK, FONT_COLOR_LIGHT } from './styling/styles';
import UserProfile from './UserManagement/UserProfile';
import Keycloak from 'keycloak-js';
import { KeycloakInstance, KeycloakRoles } from 'keycloak-js';
import { create_user, fetchRefreshToken, fetchSilentProfile } from './RemoteAPI'
import Unauthorized from './Unauthorized'

function App() {

    return (
        <Grommet full theme={theme}>
            <Router>
                <AppRouter />
            </Router>
        </Grommet>
    );
}



function AppRouter() {
    const [loggedIn, setLoggedIn] = useState<boolean>()
    const home = useSelector((state: Store) => getHomeType(state))
    const [keycloakca, setkeycloakca] = useState<KeycloakInstance>()
    const [userInfo, setuserInfo] = useState({})
    const keycloak = Keycloak('/keycloak.json');
    const history = useHistory()

    useEffect(() => {
        const access_token = new URLSearchParams(window.location.search).get('access_token')
        const refresh_token = new URLSearchParams(window.location.search).get('refresh_token')


        if (access_token && refresh_token) {
            sessionStorage.setItem('encode', refresh_token)
            fetchRefreshToken().then((data:any) => {
                // console.log('refreshed 1st time')
            sessionStorage.setItem('encode', data)
            })
            fetchSilentProfile().then((data: any) => {
                if (data === 'unauthorized') {
                    history.push('/unauthorized')
                }
                else {
                    setLoggedIn(true)
                    setuserInfo(data)
                    localStorage.setItem('login', data.preferred_username)
                    localStorage.setItem('status', 'SSO')
                    create_user(data.preferred_username)
                }
            })
        }
        else {
            keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }).success((authenticated: any) => {
                setkeycloakca(keycloak)
                if (keycloak.hasRealmRole('AIKnowledgeEnable')) {
                    setLoggedIn(authenticated)
                }
                else {
                    history.push('/unauthorized')
                }
                sessionStorage.setItem('encode', String(keycloak.refreshToken))
                keycloak.loadUserInfo().success((userInfod: any) => {
                    localStorage.setItem('status', 'login')
                    setuserInfo(userInfod)
                    localStorage.setItem('login', userInfod.preferred_username)
                    create_user(userInfod.preferred_username)
                }).error((err: any) => {
                    console.log("The error is :" + err)
                })

            }).error((err: any) => {
                console.log("The error is :" + err)
            })
        }

    
       
    }, [])


    if (localStorage.getItem('status') === 'login') {
        keycloak.onTokenExpired = () => {
            // console.log('token expired', keycloak.token);
            keycloak.updateToken(30).success(() => {
                // console.log('Successfully refreshed for Direct login', keycloak.token);
                sessionStorage.setItem('encode', String(keycloak.refreshToken))
            }).error((err: any) => { console.log(err) });
        }
    }
    if(localStorage.getItem('status') === 'SSO') {
        setInterval(function() { 
            fetchRefreshToken().then((data:any) => {
            sessionStorage.setItem('encode', data)
                // console.log('Refreshed token for SSO: ', data)
        })},1700000)
    }

    const location = useLocation()
    const background = location.state && location.state.background

    return (
        <Box fill overflow="hidden">
            {loggedIn && <Sidebar header='Menu' logout={keycloakca !== undefined ? keycloakca.logout : () => { return }} />}
            {loggedIn ?
                <Switch location={background || location}>
                    <Route exact path="/continuous_learning" component={ContinuousLearning} />
                    <Route exact path="/reading_session" component={ReadingSession} />
                    <Redirect from="/reading_session/query" to='/reading_session' exact />
                    <Route exact path="/content/:id" component={Display} />
                    <Route exact path="/image/:id" component={ImgDisplay} />
                    <Route exact path="/search" component={Query} />
                    <Route exact path="/userprofile" component={() => <UserProfile loggedIn={loggedIn} userInfo={userInfo} />} />
                    
                    <Redirect to={`/${home}`} exact />
                </Switch> :
                <Switch>
                    <Route exact path="/unauthorized" component={() => <Unauthorized logout={keycloakca !== undefined ? keycloakca.logout : () => { return }}/>}    />
                    <Redirect from="/" to={`/${home}`} exact />
                </Switch>}
            {background && <Route exact path="/content/:id" component={Display} />}
            {background && <Route exact path="/image/:id" component={ImgDisplay} />}
        </Box>
    )
}

const theme = {
    global: {
        font: {
            family: 'Roboto',
            size: '16px',
            height: '20px',
        },
        colors: {
            "accent-1": COLOR_BRAND,
            background: BG_LAYER_1,
            brand: COLOR_BRAND,
            selected: 'brand',
            text: { dark: FONT_COLOR_DARK, light: FONT_COLOR_LIGHT }
        }
    },
    layer: {
        background: BG_LAYER_1
    }
};
export type Guidelines = 'Bi-RADS' | 'C-RADS' | 'CAD-RADS' | 'HI-RADS' | 'LI-RADS' | 'Lung-RADS' | 'Ni-RADS' | 'O-RADS' | 'PI-RADS' | 'TI-RADS'

export default App;
