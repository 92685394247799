import React from 'react';
import styled, { css } from "styled-components";
import { Like } from "grommet-icons";
import { COLOR_BRAND, swell } from "./styling/styles";
import { useEffect, useState } from "react";
import { Box } from 'grommet';
import { Case } from './results/cases/Case';
import { Article } from './results/articles/Article';
import { update_votes } from './RemoteAPI';
import { Guideline } from './results/SearchResults';


type LikeProps = {
    id: string;
    index: string;
    score: number;
    direction:'column' | 'row';
    liked: boolean;
    onLike: (delta: number) => void;
}

export default function LikeButton({id, index, score, direction, liked, onLike}: LikeProps){
    const [_liked, setLiked] = useState(liked)
    const [_score, setScore] = useState(score)
    const [clicked, setClicked] = useState(false)

    useEffect(() => {
        setLiked(liked)
    }, [id])

    async function toggleLiked(){
        const delta = _liked ? -1 : 1
        try{
            setClicked(delta === 1)
            setScore(_score + delta)
            setLiked(!_liked)
            const {delta:_delta} = await update_votes(id, delta, index)
            if(_delta === 0){
                throw(Error)
            }
            onLike(delta)
        }
        catch(err){
            console.error(err)
            setLiked(_liked)
            setScore(_score)
        }
    }

    return(
        <Box direction={direction} justify="center" align={direction === 'row' ? "baseline" : "center"} gap="small">
            <span>{_score >= 100? "100+": _score }</span>

            <StyledLike data-active={_liked} data-clicked={clicked} onClick={toggleLiked}/>
        </Box>
    )
}

export function update_score({_source}: Case | Article | Guideline, delta: number){
    const { popularity_score } = _source
    _source.popularity_score = popularity_score + delta
}

// Using data-attrs gets rid of the warnings for setting incorrect dom attributes
type HackProps = {
    'data-active': boolean,
    'data-clicked': boolean
}

export const StyledLike = styled(Like)<HackProps>`
    stroke: ${p => p['data-active'] ? COLOR_BRAND : 'white'};
    animation: ${p => p['data-clicked'] ? css`${swell} 0.20s linear` : ``};
    &:hover {
        transform: scale(1.1);
        cursor: pointer;
    }
`

