import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { fetchByID } from './RemoteAPI';
import { Box, Layer } from 'grommet';
import Loading from './Loading';
import { DisplaySwitch } from './results/SearchResults';
import Header from './Header';
import { useSelector } from 'react-redux';
import { Store, getSearchResult } from './data-store';

export default function ContentDisplay({ id }: { id: string }) {
    const location = useLocation();
    const history = useHistory();

    const { results } = useSelector((state: Store) => getSearchResult(state));
    const items = results ? results.results : [];

    useEffect(() => {
        fetchDocument(id);
    }, []);

    function next() {
        const document = items[location.state.index + 1];
        history.push(`/content/${document._id}`, {
            background: location.state.background,
            index: location.state.index + 1,
            document,
        });
    }

    function prev() {
        const document = items[location.state.index - 1];
        history.push(`/content/${document._id}`, {
            background: location.state.background,
            index: location.state.index - 1,
            document,
        });
    }

    async function fetchDocument(_id: string) {
        try {
            const document = await fetchByID(_id);
            // console.log(document._source)
            // document._source.description = document._source.description ? truncate(document._source.description, 150) : document._source.description
            // document._source.discussion = document._source.discussion ? truncate(document._source.discussion, 150) : document._source.discussion
            // document._source.content = document._source.content ? truncate(document._source.content, 150) : document._source.content

            const state = location.state;
            Object.entries(document).length > 0 &&
                history.replace(location.pathname, {
                    ...state,
                    document: document,
                });
        } catch (err) {
            console.error(err);
        }
    }

    function truncate(str: String, no_words: number) {
        let sentence = str.split(' ').splice(0, no_words).join(' ');
        return sentence.substr(0, sentence.lastIndexOf('.') + 1);
    }

    return !location.state ? (
        <div>
            <Header />
            <Box fill justify="center" align="center">
                <Loading size="300px" />
            </Box>
        </div>
    ) : location.state.background ? (
        <Layer
            full
            margin="medium"
            onClickOutside={() =>
                history.push(
                    `${location.state.background.pathname}${location.state.background.search}`
                )
            }
            animation="none"
        >
            {!location.state.document ? (
                <Box fill justify="center" align="center">
                    {' '}
                    <Loading size="300px" />{' '}
                </Box>
            ) : (
                <DisplaySwitch
                    selected={location.state.document}
                    onClose={() => {
                        history.push(
                            `${location.state.background.pathname}${location.state.background.search}`
                        );
                    }}
                    onNext={
                        location.state.index < items.length - 1
                            ? next
                            : undefined
                    }
                    onPrev={location.state.index > 0 ? prev : undefined}
                />
            )}
        </Layer>
    ) : (
        <Box>
            <Header />
            {!location.state.document ? (
                <Box fill justify="center" align="center">
                    {' '}
                    <Loading size="300px" />{' '}
                </Box>
            ) : (
                <DisplaySwitch
                    selected={location.state.document}
                    onClose={() => history.push('/')}
                />
            )}
        </Box>
    );
}
