import React from 'react'
import { Tags, Thumbnail } from './CaseCard';
import { Box, Text } from 'grommet';
import { RadiopaediaCase } from '../RadiopaediaCase';
import { useHistory, useLocation } from 'react-router';

export default function RadiopaediaCaseCard(document: RadiopaediaCase, index: number) {
    const { tags, authors, date, "diagnostic certainty": certainty, "search completeness": completeness, title, url, image } = document._source
    const history = useHistory();
    const location = useLocation();
    return {
        Heading: title,
        Source: url,
        Blurb: (
            <Box direction="column" justify="start" gap="small">
                <Box direction="row" justify="start" align="center" gap="small">
                    <Box direction="column" justify="start" gap="small">
                        <Text>{certainty.trim()}</Text>
                        <Text>{authors}</Text>
                        <Text>{date}</Text>
                        <Text>{completeness}</Text>
                    </Box>
                    <Thumbnail src={image} onClick={() => history.push(`/image/${document._id}`, {
                        background: location,
                        document: document
                    })} />
                </Box>
                {tags && <Tags tags={tags.split(',')} />}
            </Box>
        )
    }
}