import { Document, Results, SearchResult } from './results/SearchResults';

const backendURL = '/api' // Just use relative routes, backend and frontend co-exist. 

const baseRoute = `${backendURL}`;
const searchURL = `${baseRoute}/search`;
const activityURL = `${baseRoute}/updateactivity`;
export const getPopularURL = `${baseRoute}/popular`;
export const getSystemSelectedURL = `${baseRoute}/systempop`; // aka recommended
export const getBookmarksURL = `${baseRoute}/user_bookmarks`;
const voteURL = `${baseRoute}/update_votes`;
const updateBookmarkURL = `${baseRoute}/update_bookmarks`;
const loginURL = `${baseRoute}/create_user`;
const guidelinesURL = `${baseRoute}/guidelines`;
const updateBlacklistURL = `${baseRoute}/update_blacklist`;
const getBlacklistURL = `${baseRoute}/blacklist`;
const clearBlacklistURL = `${baseRoute}/clear_blacklist`;
const reportLinkURL = `${baseRoute}/report_broken_link`;
const createProfileURL = `${baseRoute}/createprofile`;
const fetchProfileURL = `${baseRoute}/users`;
const updateProfileURL = `${baseRoute}/updateuser`;
const validateAuntminnieURL = `${baseRoute}/validateAunt`;
const fetchSilentProfileURL = `${baseRoute}/get_user_profile`
const fetchRefreshURL = `${baseRoute}/update_token`


export type ContentType =
    | 'article'
    | 'case'
    | 'mixed'
    | 'images'
    | 'guideline';

export type SearchMode = 'exact' | 'fuzzy';
export type DeleteDirection = 'left' | 'right';
export type SortMode = 'elastic' | 'completeness';

export type Bookmarks = Array<AuxItem>;
export type Recommended = Array<AuxItem>;
export type Popular = Array<AuxItem>;

export type AuxItem = {
    title: string;
    id: string;
    type: ContentType;
    date: string;
    url: string;
    published?: string;
};

export function getToken() {
    const token = sessionStorage.getItem('encode');
    // console.log(uid)
    return token || 'test';
}

export const fetcherURL = (url: string) =>
    fetch(`${url}?size=40&user_id=${getUID()}&status=${getStatus()}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: getToken(),
        },
    }).then((r) => r.json());

export function fetchByID(_id: string): Promise<Document> {
    const date = new Date().toLocaleDateString('en-CN');
    const _searchURL = `${searchURL}/${_id}?date=${date}&user_id=${getUID()}&status=${getStatus()}`;

    return withTimeout(
        4000,
        fetch(`${_searchURL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
        })
    )
        .then((res) => res.json())
        .catch((err) => {
            throw Error(err);
        });
}

export function updateActivity(_id: string): Promise<Document> {
    const date = new Date().toLocaleDateString('en-CN');
    const _searchURL = `${activityURL}?date=${date}&id=${_id}&user_id=${getUID()}&status=${getStatus()}`;

    return withTimeout(
        4000,
        fetch(`${_searchURL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
        })
    )
        .then((res) => res.json())
        .catch((err) => {
            throw Error(err);
        });
}

export function fetchPopular(size: number): Promise<Results> {
    return withTimeout(
        4000,
        fetch(
            `${getPopularURL}?size=${size}&user_id=${getUID()}&status=${getStatus()}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        )
    )
        .then((res) => res.json())
        .catch((err) => {
            throw Error(err);
        });
}

export function fetchBlacklist(size: number): Promise<Results> {
    return withTimeout(
        4000,
        fetch(
            `${getBlacklistURL}?size=${size}&user_id=${getUID()}&status=${getStatus()}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        )
    )
        .then((res) => res.json())
        .catch((err) => {
            throw Error(err);
        });
}

export function clearBlacklist(): Promise<Results> {
    return withTimeout(
        4000,
        fetch(
            `${clearBlacklistURL}?status=${getStatus()}&user_id=${getUID()}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        )
    ).then((res) => res.json());
}

export function fetchSystemSelected(size: number): Promise<Results> {
    return withTimeout(
        4000,
        fetch(
            `${getSystemSelectedURL}?size=${size}&user_id=${getUID()}&status=${getStatus()}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        )
    )
        .then((res) => res.json())
        .catch((err) => {
            throw Error(err);
        });
}

export function fetchBookmarks(size: number): Promise<Results> {
    return withTimeout(
        4000,
        fetch(
            `${getBookmarksURL}?size=${size}&user_id=${getUID()}&status=${getStatus()}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        )
    )
        .then((res) => res.json())
        .catch((err) => {
            throw Error(err);
        });
}

export function fetchGuidelines(guideline?: string): Promise<SearchResult> {
    const _guideline = typeof guideline === 'undefined' ? 'rads' : guideline;
    return withTimeout(
        4000,
        fetch(
            `${guidelinesURL}?name=${_guideline}&user_id=${getUID()}&status=${getStatus()}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        )
    )
        .then((res) => res.json())
        .catch((err) => {
            throw Error(err);
        });
}

type ContentParams = {
    query: string;
    page: number;
    size: number;
    category: string[];
    source: string[];
    modality: string[];
    searchMode: SearchMode;
    deleteDirection: DeleteDirection;
    sortMode: SortMode;
    type: ContentType;
};

export function fetchContent(params: ContentParams): Promise<SearchResult> {
    const {
        query,
        page,
        size,
        category,
        source,
        modality,
        searchMode,
        type,
        sortMode,
    } = params;
    return withTimeout(
        4000,
        fetch(
            `${searchURL}?page=${page}&status=${getStatus()}&user_id=${getUID()}&size=${size}&query=${query}&mode=${searchMode}&sort=${sortMode}${buildParameterList(
                [...category],
                'category'
            )}${buildParameterList(
                [...modality],
                'modality'
            )}${buildParameterList(source, 'source')}${contentTypeMap[type]}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        )
    )
        .then((res) => res.json())
        .catch((err) => {
            throw Error(err);
        });
}

export function buildParameterList(array: string[], parameter: string) {
    if (array.length === 0) {
        return `&${parameter}=`;
    }
    let paramList = '';
    for (let item of array) {
        paramList += `&${parameter}=${item}`;
    }
    return paramList;
}

export function withTimeout<T = Response>(
    ms: number,
    promise: Promise<any>
): Promise<T> {
    return new Promise((resolve, reject) => {
        const id = setTimeout(() => {
            reject(new Error('timed out'));
        }, ms);
        promise.then(
            (res) => {
                clearTimeout(id);
                resolve(res);
            },
            (err) => {
                clearTimeout(id);
                reject(err);
            }
        );
    });
}

export function update_votes(
    id: string,
    vote: number,
    index: string
): Promise<{ delta: number }> {
    const date = new Date().toLocaleDateString('en-CN');
    return withTimeout(
        4000,
        fetch(
            `${voteURL}?id=${id}&index=${index}&delta=${vote}&date=${date}&status=${getStatus()}&user_id=${getUID()}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        )
    )
        .then((res) => res.json())
        .catch((err) => {
            throw Error(err);
        });
}

export function update_bookmarks(id: string): Promise<{ code: number }> {
    const date = new Date().toLocaleDateString('en-CN') + ' ' + Date.now();
    return withTimeout(
        4000,
        fetch(
            `${updateBookmarkURL}?id=${id}&date=${date}&status=${getStatus()}&user_id=${getUID()}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        )
    )
        .then((res) => res.json())
        .catch((err) => {
            throw Error(err);
        });
}

export function update_blacklist(id: string): Promise<{ code: number }> {
    return withTimeout(
        4000,
        fetch(
            `${updateBlacklistURL}?id=${id}&status=${getStatus()}&user_id=${getUID()}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        )
    )
        .then((res) => res.json())
        .catch((err) => {
            throw Error(err);
        });
}

export function getUID() {
    const uid = localStorage.getItem('login');
    // console.log(uid)
    return uid || 'test';
}
export function getStatus() {
    const status = localStorage.getItem('status');
    // console.log(uid)
    return status || 'login';
}

export function create_user(uid: string) {
    return withTimeout(
        4000,
        fetch(`${loginURL}?status=${getStatus()}&user_id=${uid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
        })
    ).then((res) => res.json());
}

export function report_broken_link(index: string, id: string, url: string) {
    return withTimeout(
        4000,
        fetch(
            `${reportLinkURL}?id=${id}&index=${index}&url=${url}&user_id=${getUID()}&status=${getStatus()}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        )
    );
}

export const contentTypeMap: { [key in ContentType]: string } = {
    article: '&type=article',
    case: '&type=case',
    mixed: '&type=article&type=case',
    images: '&type=case',
    guideline: '&type=guidelines',
};

export function createProfile(user: Object) {
    return withTimeout(
        4000,
        fetch(`${createProfileURL}?status=${getStatus()}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
            body: JSON.stringify(user),
        })
    )
        .then((res) => res.json())
        .catch((err) => {
            throw Error(err);
        });
}

export function fetchProfile(user_id: string) {
    return withTimeout(
        4000,
        fetch(`${fetchProfileURL}?user_id=${user_id}&status=${getStatus()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
        })
    )
        .then((res) => res.json())
}

export function updateProfile(user: Object) {
    return withTimeout(
        4000,
        fetch(`${updateProfileURL}?status=${getStatus()}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
            body: JSON.stringify(user),
        })
    )
        .then((res) => res.json())
        .catch((err) => {
            throw Error(err);
        });
}

export function validateAuntminnie(user: string, pass: string) {
    return withTimeout(
        30000,
        fetch(
            `${validateAuntminnieURL}?user=${user}&pass=${pass}&status=${getStatus()}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                },
            }
        )
    )
        .then((res) => res.json())
        .catch((err) => {
            throw Error(err);
        });
}

export function fetchSilentProfile() {
    return withTimeout(4000, fetch(`${fetchSilentProfileURL}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': getToken() },
    }))
        .then(res => res.json())
        .catch(err => { window.location.replace("/reading_session")
                        throw Error(err) 
                        })
}
export function fetchRefreshToken() {
    return withTimeout(4000, fetch(`${fetchRefreshURL}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': getToken() },
    }))
        .then(res => res.json())
        .catch(err => { throw Error(err) })
}

