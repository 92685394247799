import React from 'react'
import { Tags, Thumbnail } from './CaseCard';
import { Box, Text } from 'grommet';
import { AuntMinnieCase } from '../AuntminnieCase';
import { useHistory, useLocation } from 'react-router';

export default function AuntminnieCaseCard(document: AuntMinnieCase, index: number){
    const {tags, authors, date, description, image} = document._source
    let {url}= document._source
    url = `https://${url}`
    const history = useHistory();
    const location = useLocation();

    return{
            Heading: description,
            Source: url,
            Blurb: (
                <Box direction="column" justify="start" gap="small">
                    <Box direction="row" justify="start" align="center" gap="small">
                        <Box direction="column" justify="start" gap="small">
                            <Text>{authors}</Text>
                            <Text>{date}</Text>
                        </Box>
                        <Thumbnail src={`https://${image}`} onClick={() => history.push(`/image/${document._id}`, {
                        background: location,
                        document: document
                    })}/>
                    </Box>
                    {tags && <Tags tags={tags.replace('Tags:', '').split(',')}/>}
                </Box>
            )
    }
}