import { Box } from 'grommet';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BG_LAYER_3 } from '../../../styling/styles';
import { BaseCard } from '../../Card';
import { Case } from '../Case';
import AuntminnieCaseCard from './AuntminnieCaseCard';
import RadiopaediaCaseCard from './RadiopaediaCaseCard';

type Props = {
    document: Case;
    index: number;
};
export default function CaseCard(props: Props) {
    const { document, index } = props;
    const { Heading, Source, Blurb } = CardSwitch(document, index);
    const location = useLocation();
    return (
        <div>
            <BaseCard
                document={document}
                index={index}
                Heading={Heading}
                Source={Source}
                Blurb={Blurb}
                location={location}
                type="Case"
            />
        </div>
    );
}

function CardSwitch(document: Case, index: number) {
    switch (document._index) {
        case 'radiopedia':
            return RadiopaediaCaseCard(document, index);
        case 'auntminnie':
            return AuntminnieCaseCard(document, index);
    }
}

export function Tags({ tags }: { tags: string[] }) {
    return (
        <Box direction="row" gap="small" wrap>
            {tags.map((tag, idx) => (
                <Tag key={idx}>{tag}</Tag>
            ))}
        </Box>
    );
}

export const Thumbnail = styled.img`
    max-width: 150px;
    max-height: 150px;
    height: auto;
    cursor: pointer;
    box-sizing: border-box;
    &:hover {
        border: 1px solid white;
    }
`;

const Tag = styled.span`
    background-color: ${BG_LAYER_3};
    padding: 5px 10px;
    font-weight: 400;
    border-radius: 20px;
    margin-bottom: 6px;
`;
