import { Box } from 'grommet/components/Box';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import placeholder from '../images/placeholder.svg';
import Loading from '../Loading';
import Pagination from '../Pagination';
import GuidelineCard from './guidelines/GuidelineCard';
import { ArticleHeader, Card, Results, SearchResult } from './SearchResults';

interface PrimeP {
    result: SearchResult | null;
    loading: boolean;
}

interface SubP {
    result: SearchResult;
}

const BoxedLoading = () => (
    <Box fill justify="center" align="center" flex="grow" pad="large">
        <Loading size="300px" />
    </Box>
);

export function AllResults({ result, loading }: PrimeP) {
    if (loading || !result) return <BoxedLoading />;
    const { results } = result;

    return (
        <Box
            fill
            direction="row"
            pad="small"
            justify="start"
            gap="small"
            overflow={{ vertical: 'auto', horizontal: 'hidden' }}
        >
            <Box flex={{ grow: 3 }} basis="0">
                {results.map((result, idx) => (
                    <Card key={idx} document={result} index={idx} />
                ))}
                <span style={{ marginRight: 'auto' }}>
                    <ContentFooter result={result} />
                </span>
            </Box>
            <Box
                flex={{ grow: 2 }}
                basis="0"
                direction="row"
                wrap
                justify="center"
                gap="small"
            >
                <ImageCollection result={result} />
            </Box>
        </Box>
    );
}

function BaseLayout({ result, loading, children }: PrimeP & { children: any }) {
    if (loading || !result) return <BoxedLoading />;
    return (
        <Box
            fill
            pad="small"
            gap="small"
            overflow={{ vertical: 'auto', horizontal: 'hidden' }}
        >
            {children}
            <span style={{ marginRight: 'auto' }}>
                <ContentFooter result={result} />
            </span>
        </Box>
    );
}

export function Articles({ result, loading }: PrimeP) {
    if (loading || !result) return <BoxedLoading />;
    const { results } = result;

    return (
        <BaseLayout result={result} loading={loading}>
            {results.map((result, idx) => (
                <Card key={idx} document={result} index={idx} />
            ))}
        </BaseLayout>
    );
}

export function Cases({ result, loading }: PrimeP) {
    if (loading || !result) return <BoxedLoading />;
    const { results } = result;
    return (
        <BaseLayout result={result} loading={loading}>
            {results.map((result, idx) => (
                <Card key={idx} document={result} index={idx} />
            ))}
        </BaseLayout>
    );
}

export function Images({ result, loading }: PrimeP) {
    if (loading || !result) return <BoxedLoading />;
    return (
        <Box
            fill
            pad="small"
            gap="small"
            direction="row"
            justify="center"
            wrap
            overflow={{ vertical: 'auto', horizontal: 'hidden' }}
        >
            <ImageCollection result={result} />
            <span style={{ marginRight: 'auto', flexBasis: '100%' }}>
                <ContentFooter result={result} />
            </span>
        </Box>
    );
}

// TODO this needs to turn down the suck
export function Guidelines({ result, loading }: PrimeP) {
    if (loading || !result) return <BoxedLoading />;
    const { results } = result;
    return results.length != 0 ? (
        <Box
            fill
            pad="small"
            gap="small"
            overflow={{ vertical: 'auto', horizontal: 'hidden' }}
        >
            <GuidelineList guidelines={results} />
        </Box>
    ) : (
        <Box fill pad="small" gap="small" direction="row" justify="center">
            <ContentFooter result={result} />
        </Box>
    );
}

// TODO this needs to turn down the suck even more
function GuidelineList({ guidelines }: { guidelines: Results }) {
    return (
        <GuidelineError>
            <div>
                {guidelines.map(
                    (g, i) =>
                        g._type == 'guideline' && (
                            <Box
                                key={i}
                                direction="column"
                                border="bottom"
                                gap="small"
                                pad={{ bottom: 'small' }}
                                margin={{ bottom: 'small' }}
                            >
                                <GuidelineCard document={g} index={i} />
                            </Box>
                        )
                )}
            </div>
        </GuidelineError>
    );
}

// TODO remove once the suck is turned down
class GuidelineError extends React.Component<{}, { hasError: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch() {
        // You can also log the error to an error reporting service
        // console.log('fix guidelines');
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return null;
        }

        return this.props.children;
    }
}

function openLink(url: string) {
    window.open(url);
}

function ImageCollection({ result }: SubP) {
    const { results } = result;
    const history = useHistory();
    const location = useLocation();

    location.state = { ...location.state, results: result.results };
    return (
        <>
            {results.map(
                (result, idx) =>
                    result._type === 'case' && (
                        <Image
                            key={result._id}
                            idx={idx}
                            src={
                                result._index === 'auntminnie'
                                    ? `https://${result._source.image}`
                                    : result._source.image
                            }
                            alt={result._source.description}
                            onClick={() =>
                                history.push(`/image/${result._id}`, {
                                    background: location,
                                    index: idx,
                                    document: result,
                                })
                            }
                        />
                    )
            )}
        </>
    );
}

type ImageProps = {
    idx: number;
    src: string;
    alt: string;
    onClick: (index: number) => void;
};

export function Image(props: ImageProps) {
    const [loaded, setLoaded] = useState(true);
    const { idx, src, alt, onClick } = props;
    const _src = loaded ? src : placeholder;
    return (
        <Frame>
            <Img
                key={idx}
                src={_src}
                alt={alt}
                onClick={() => onClick(idx)}
                onError={() => setLoaded(false)}
            />
        </Frame>
    );
}

const Img = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
    &:hover {
        cursor: pointer;
        border: 1px solid white;
    }
    overflow: hidden;
`;

const Frame = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    min-width: 200px;
    max-width: 300px;
    min-height: 200px;
    max-height: 300px;
    margin-bottom: 12px;
    box-sizing: border-box;
    border: 1px solid black;
    background-color: black;
`;

function ContentFooter({ result }: SubP) {
    const { results, total_results } = result;
    if (!results.length) return <NoResults />;

    return <Pagination totalResults={total_results} display={5} />;
}

const NoResults = () => (
    <Box
        fill
        direction="column"
        gap="medium"
        margin={{ bottom: 'small' }}
        pad="medium"
        animation={{
            type: 'fadeIn',
            delay: 600,
            duration: 250,
            size: 'large',
        }}
    >
        <ArticleHeader size="large">No results found</ArticleHeader>
    </Box>
);
