import { Text } from "grommet";
import React from 'react';
import { BaseArticle, Row, Title } from "./Article";
import { Header } from '../Card';

export interface PubmedArticle extends BaseArticle {
    _index: 'pubmed';
    _source: {
        authors: Author[];
        popularity_score: number;
        citations: number;
        conclusions: string | null;
        copyrights: string | null;
        date: string;
        description: string | null;
        doi: string | null;
        journal: string;
        keywords: string[];
        methods: string | null;
        pubmed_id: string;
        results: string | null;
        title: string;
        url: string;
    }
}

interface Author {
    affiliation: string;
    firstname: string;
    initials: string;
    lastname: string;
}

export default function Pubmed(document: PubmedArticle){
    const {
        authors,
        citations,
        conclusions,
        copyrights,
        date,
        description,
        doi,
        journal,
        keywords,
        methods,
        pubmed_id,
        results,
        title,
        url,
    } = document._source
    return {
        Left: ( 
            <>
                <Title text={title} url={url}/>
                <Header>{AuthorArray(authors)}</Header>
                {description && <Text margin="xsmall">{description}</Text>}
                {methods && <Text margin="xsmall">{methods}</Text>}
                {results && <Text margin="xsmall">{results}</Text>}
                {conclusions && <Text margin="xsmall">{conclusions}</Text>}
            </>
        ),
        Right: (
            <>
                <Row label="Author(s):" field={AuthorArray(authors)}/>
                <Row label="Date:" field={date}/> 
                <Row label="Keywords:" field={KeywordArray(keywords)} />
                <Row label="Citations:" field={citations} />
                <Row label="Journal:" field={journal} />
                {doi && <Row label="DOI:" field={doi} /> }
                <Row label="Pubmed ID:" field={pubmed_id} />
                {copyrights && <Row label="DOI:" field={copyrights} /> }
            </>
        ),
        Title: title
    }
}

function AuthorArray(authors: Author[]){
    return <>{authors.map(Author)}</> 
}

function Author(author: Author, index: number, authors: Author[]){
    const length = authors.length
    const {firstname, lastname} = author
    return (
        <span key={index}>
            <Text size="medium">{firstname} {lastname}</Text>
            <Text size="medium">{index < (length - 1) && ','}&nbsp;</Text>   
        </span>
    )
}

function KeywordArray(keywords: string[]){
    return <>{keywords.map(Keyword)}</> 
}

function Keyword(keyword: string, index: number, keywords: string[]){
    const length = keywords.length
    return (
        <span key={index}>
            <Text size="medium">{keyword}</Text>
            <Text size="medium">{index < (length - 1) && ','}&nbsp;</Text>   
        </span>
    )
}