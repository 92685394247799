import { JSXAttribute } from "@babel/types";
import { Anchor, Box, Heading, Select, Text, TextInput } from "grommet";
import { Search } from "grommet-icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  generateSortOptions,
  SortOption,
  useSortableData,
} from "./hooks/sortable-data";
import Loading from "./Loading";
import { Bookmarks, Popular, Recommended, updateActivity } from "./RemoteAPI";
import { BG_LAYER_0, BG_LAYER_1, GridProps } from "./styling/styles";
import { DataType, useWindowSize, useSearch } from "./util";
import { useDispatch } from "react-redux";
import { setQuery } from "./data-store";

import Close from "./images/close.svg";

type ContentBoxProps = {
  content: Popular | Recommended | Bookmarks;
  contents: string;
  onRemoveItem: (event: React.MouseEvent<HTMLImageElement>) => void;
};

type ContentSelectedProps = {
  contents: DataType;
  heading: string;
};

const MIN_CONTAINER_WIDTH = "400px" as const;
const DEFAULT_CONTAINER_HEIGHT = "400px" as const;

export default function Boxes() {
  const popular: DataType = "popular";
  const recommended: DataType = "recommended";
  const bookmark: DataType = "bookmark";
  const [width] = useWindowSize();
  const direction = width >= 1280 ? "row" : "column";

  return (
    <div style={{ overflow: "auto", minWidth: "100vw", height: "100%" }}>
      <BoxGroup direction={direction} gap="small" margin="medium">
        <ContentBox contents={bookmark} heading="Self Selected" />
        <ContentBox contents={popular} heading="Peer Selected" />
        <ContentBox contents={recommended} heading="System Selected" />
      </BoxGroup>
    </div>
  );
}

function ContentBox({ contents, heading }: ContentSelectedProps) {
  const [loading, setLoading] = useState<boolean>(true)
  const [content, remove, restore, setSort, setFilter] = useSortableData(
    contents
  );
  // console.log(content)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);
    return () => clearTimeout(timer);
  }, []);

  function handleRemoveItem(event: React.MouseEvent<HTMLImageElement>) {
    let clicked = event.currentTarget;
    let id = "";

    if (clicked && clicked.getAttribute("id")) {
      id = clicked.getAttribute("id")!;
    }
    remove(id);
    restore();
  }

  function handleSort(event: React.ChangeEvent<JSXAttribute>) {
    let result = event ? (event as any).value : "";
    setSort(result);
    return result;
  }

  function fuzzySearch(value: string) {
    const searchVal = value;
    setFilter(searchVal);
  }

  return (
    <div
      className={contents}
      style={{ width: "32%", minWidth: MIN_CONTAINER_WIDTH }}
    >
      <Heading level={3} margin={{ bottom: "xsmall", top: "0" }}>
        {heading}:
      </Heading>
      <Box
        className="container"
        direction="column"
        justify="start"
        border
        gap="small"
        pad="small"
        style={{
          backgroundColor: BG_LAYER_0,
          height: DEFAULT_CONTAINER_HEIGHT,
        }}
      >
        <FilterSection>
          <MiniSearchBar onSearch={fuzzySearch} />
          <SortDropdown handleSort={handleSort} />
        </FilterSection>

        {content.length === 0 ? (
          <Box fill justify="center" align="center">
            { loading ? <Loading size="200px" />: <Text weight="bold">You have not selected any bookmarks </Text>}
          </Box>
        ) : (
          <ContentList
            content={content}
            onRemoveItem={handleRemoveItem}
            contents={contents}
          />
        )}

        <Text textAlign="end" size="small">
          Items Listed: {content ? content.length : 0}
        </Text>
      </Box>
    </div>
  );
}

function ContentList({ content, onRemoveItem, contents }: ContentBoxProps) {
  // console.log(content)
  if (contents === "recommended") {
    return (
      <Box
        fill
        gap="small"
        align="center"
        overflow={{ vertical: "auto", horizontal: "hidden" }}
      >
        {content ? (
          content.map((r) => (
            <ContentItem key={r.id} item={r} handleRemove={onRemoveItem} />
          ))
        ) : (
          <Box fill justify="center" align="center">
            <Loading size="200px" />
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <Box
        fill
        gap="small"
        align="center"
        overflow={{ vertical: "auto", horizontal: "hidden" }}
      >
        {content ? (
          content.map((r) => (
            <ContentItem key={r.id} item={r} handleRemove={onRemoveItem} />
          ))
        ) : (
          <Box fill justify="center" align="center">
            <Loading size="200px" />
          </Box>
        )}
      </Box>
    );
  }
}

const ContentItem = ({
  item,
  handleRemove,
}: {
  item: any;
  handleRemove: (event: React.MouseEvent<HTMLImageElement>) => void;
}) => {
  const history = useHistory();
  const location = useLocation();
  const searchHandler = useSearch();
  const dispatch = useDispatch();
  const updateQuery = (newQuery: string) => dispatch(setQuery(newQuery));

  const [width] = useWindowSize();
  const container = document.getElementsByClassName("container");
  const [containerSize, setContainerSize] = useState(
    container && container[0] ? container[0].clientWidth - 175 : 300
  );

  useEffect(() => {
    setContainerSize(
      container && container[0] ? container[0].clientWidth - 175 : 300
    );
  }, [width]);

  function clickHandler() {
    if (item.type === "guideline") {
      window.open(item.url);
    } else if (item.type === "All results") {
      updateQuery(item.titlepruned);
      updateActivity(item.id);
      searchHandler();
    } else {
      history.push(`/content/${item.id}`, { background: location });
    }
  }

  const strBuffer = containerSize / 3 - 25;

  let title = item.title;
  if (title.length >= strBuffer) title = title.substring(0, strBuffer) + "...";

  let date = item.date;
  let published = item.published;

  if (item.date) {
    // const temp = new Date(item.date).toISOString().substr(0, 10);;
    date = `Last Accessed: ${item.date.substr(0, 10)}`;
  }
  if (item.published) {
    // const temp = new Date(item.date).toISOString().substr(0, 10);;
    published = `${item.published}`;
  }
  // const match = item.published ? item.published.match('([0-9].*)') : []
  return (
    <ContentGridContainer col1={containerSize + "px"}>
      <LinkContainer>
        <Anchor title={item.title} label={title} onClick={clickHandler} />
      </LinkContainer>

      <CloseContainer>
        <RemoveItem id={item.id} className={item.id} onClick={handleRemove} />
      </CloseContainer>
      <DateContainer>
        <StyledDate>{ published && `Published: ${published}` }</StyledDate>
        <StyledDate>{date}</StyledDate>
      </DateContainer>
      <TypeContainer>
        <StyledType>{item.type}</StyledType>
      </TypeContainer>
    </ContentGridContainer>
  );
};

const RemoveItem = ({
  id,
  className,
  onClick,
}: {
  id: string;
  className: string;
  onClick: (event: React.MouseEvent<HTMLImageElement>) => void;
}) => {
  return (
    <StyledX
      id={id}
      className={className}
      onClick={onClick}
      src={String(Close)}
    />
  );
};

const sortList = generateSortOptions();

const SortDropdown = ({
  handleSort,
}: {
  handleSort: (event: React.ChangeEvent<JSXAttribute>) => string;
}) => {
  const [sorted, setSorted] = useState(sortList[0]);

  return (
    <Box style={{ backgroundColor: BG_LAYER_0, width: "150px" }}>
      <Select
        options={sortList}
        value={sorted}
        onChange={(option) => setSorted(handleSort(option) as SortOption)}
        dropHeight="medium"
        size="small"
      />
    </Box>
  );
};

const MiniSearchBar = ({ onSearch }: { onSearch: (value: string) => void }) => {
  const [value, setValue] = useState<string>("");

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      onSearch(event.currentTarget.value);
      event.currentTarget.blur();
    }
  }

  return (
    <MiniSearch>
      <Search
        style={{
          position: "absolute",
          left: "0",
          padding: "9px 8px",
        }}
      />
      <StyledBar
        placeholder="search"
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onKeyDown={handleKeyDown}
      />
    </MiniSearch>
  );
};

const StyledX = styled.img`
  background-color: none;
  fill: blue;
  transform: scale(0.75);
  &:hover {
    transform: scale(1);
    cursor: pointer;
  }
`;
const BoxGroup = styled(Box)`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: center;
  align-items: center;
`;

const StyledBar = styled(TextInput)`
  padding-left: 40px;
`;

const ContentGridContainer = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${(props) => props.col1 || "80%"} 100px;
  grid-template-rows: 55px 45px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: ${BG_LAYER_1};
`;

const LinkContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row: 1;
  margin-left: 10px;
`;

const CloseContainer = styled.div`
  grid-column: 2;
  grid-row: 1;
  text-align: right;
`;

const DateContainer = styled.div`
  padding: 10px 0 0 10px;
  grid-column: 1;
  grid-row: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const StyledDate = styled.span`
  height: fit-content;
`;

const TypeContainer = styled.div`
  padding-top: 10px;
  grid-column: 2;
  grid-row: 2;
  display: flex;
  text-align: right;
  align-items: flex-end;
  justify-content: flex-end;
`;

const StyledType = styled.span`
  height: fit-content;
`;

const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MiniSearch = styled.div`
  width: 250px;
  position: relative;
`;
