import { ContentType, SearchMode, DeleteDirection, SortMode } from "../RemoteAPI";
import { HomePage } from "./reducers";
import { Filters } from "../menu/MenuBundles";
import { SearchResult } from "../results/SearchResults";

const SET_HOME = 'SET_HOME' as const;
const SET_QUERY = 'SET_QUERY' as const;
const SET_CONTENT_TYPE = 'SET_CONTENT_TYPE' as const;
const SET_PAGE = 'SET_PAGE' as const; 
const SET_PAGE_SIZE = 'SET_PAGE_SIZE' as const;
const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS' as const;
const SET_FILTER = 'SET_FILTER' as const;
const SET_SEARCH_MODE = 'SET_SEARCH_MODE' as const;
const SET_DELETE_DIRECTION = 'SET_DELETE_DIRECTION' as const;
const SET_SORT_MODE = 'SET_SORT_MODE' as const;
const SET_RESULTS = 'SET_RESULTS' as const;
const SET_LOADING = 'SET_LOADING' as const;
const SET_TOGGLE_MODE = 'SET_TOGGLE_MODE' as const;
const SET_AUNTMINNIE_AUTH = 'SET_AUNTMINNIE_AUTH' as const;


export const setHome = (page: HomePage) => (
    {type: SET_HOME, page})

export const setQuery = (query: string) => ({type: SET_QUERY, query}) as const;
export const setContentType = (contentType: ContentType) => (
    {type: SET_CONTENT_TYPE, contentType}) as const;
export const setPage = (page: number) => (
    {type: SET_PAGE, page}) as const;
export const setPageSize = (pageSize: number) => ({type: SET_PAGE_SIZE, pageSize}) as const;
export const setSearchParams = ({
    query, 
    contentType, 
    pageSize,
    page,
    filters,
    searchMode,
    deleteDirection
}: SearchParams) => (
    {type: SET_SEARCH_PARAMS, query, contentType, pageSize, page, filters, deleteDirection}
)
export const setFilter = (filters: Filters) => (
    {type: SET_FILTER, filters}
)

export const setSearchMode = (mode: SearchMode) => (
    {type: SET_SEARCH_MODE, mode}
)

export const setDeleteDirection = (deleteDirection: DeleteDirection) => (
    {type: SET_DELETE_DIRECTION, deleteDirection}
)

export const setSortMode = (sortMode: SortMode) => (
    {type: SET_SORT_MODE, sortMode}
)

export const setResults = (result: SearchResult) => (
    {type: SET_RESULTS, result}
)
export const setAuntminnieAuth = (param: boolean) => (
    {type: SET_AUNTMINNIE_AUTH, param}
)
export const setLoading = () => ({type: SET_LOADING})

export const setToggleMode = (toggleMode: boolean) => ({type: SET_TOGGLE_MODE, toggleMode}) as const;

export type Action = ReturnType<typeof setQuery> | ReturnType<typeof setContentType> |
    ReturnType<typeof setPageSize> | ReturnType<typeof setHome> | ReturnType<typeof setSearchParams> |
    ReturnType<typeof setFilter> | ReturnType<typeof setSearchMode> | ReturnType<typeof setResults> | ReturnType<typeof setLoading> |
    ReturnType<typeof setPage> | ReturnType<typeof setToggleMode> | ReturnType<typeof setDeleteDirection> | ReturnType<typeof setSortMode> | ReturnType<typeof setAuntminnieAuth> 

export type SearchParams = {
    query?: string, 
    contentType?: ContentType, 
    pageSize?: number,
    page?: number,
    filters?: Filters,
    searchMode?: SearchMode,
    deleteDirection?: DeleteDirection,
    sortMode?: SortMode
}
