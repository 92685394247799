import React from 'react';
import styled, { css } from "styled-components";
import { Bookmark } from "grommet-icons";
import { COLOR_BRAND, swell, BG_LAYER_0 } from "./styling/styles";
import { useEffect, useState } from "react";
import { Box, Anchor, Heading } from 'grommet';
import { useHistory } from 'react-router';
import { ContentType, update_bookmarks } from './RemoteAPI';

type BookmarkContentType = 'article' | 'case';

type BookmarkButtonProps = {
    id: string,
    bookmarked: boolean
}


export function BookmarkButton({id, bookmarked}: BookmarkButtonProps){
    const [_bookmarked, setBookmarked] = useState(bookmarked)
    const [clicked, setClicked] = useState(false)

    useEffect(() => {
        setBookmarked(bookmarked)
    }, [id])

    async function toggleBookmarked(){
        try{
            setBookmarked(!_bookmarked)
            setClicked(!_bookmarked)
            const res = await update_bookmarks(id)
            if(res.code === 0){
                throw(Error)
            }
        }
        catch(err){
            var start = new Date().getTime();
            var end = start;
            while(end < start + 2000) {
                end = new Date().getTime();
            }
            console.error(err)
            setBookmarked(_bookmarked)
        }
    }

    return(
        <StyledBookmark data-active={_bookmarked} data-clicked={clicked} onClick={toggleBookmarked} />
    )
}

type BookmarkBoxObj = {
    bookmarks: {[key:string]:string} | never[],
    contentType: ContentType
}

export function BookmarkBox({bookmarks, contentType}: BookmarkBoxObj){
    const history = useHistory()
    return(
        <Box 
            direction="column" 
            justify="start" 
            gap="small" 
            border 
            pad="small" 
            style={{backgroundColor: BG_LAYER_0, width: "350px", height: "350px"}}
        >
            <Heading level={3} margin={{vertical: "xxsmall"}}>Self Selected :</Heading>
            <Box gap="small" overflow={{vertical: 'auto'}}>
            {Object.entries(bookmarks).map(([key, value]) => {
                const [title, type] = value.split('~~~')
                if (contentType === 'article' && type === 'case')
                    return
                else if ((contentType === 'images' || contentType === 'case') && type === 'article')
                    return
                return  (
                    <div>
                        <Anchor label={title} onClick={() => history.push(`/content/${key}`)}/>
                        <BookmarkButton id={key} bookmarked={true}/>
                    </div>
                )
            })}
            </Box>
        </Box>
    )
}

// Using data-attrs gets rid of the warnings for setting incorrect dom attributes
type HackProps = {
    'data-active': boolean,
    'data-clicked': boolean
}

export const StyledBookmark = styled(Bookmark)<HackProps>`
    stroke: ${p => p['data-active'] ? COLOR_BRAND : 'white'};
    fill: blue;
    animation: ${p => p['data-clicked'] ? css`${swell} 0.20s linear` : ``};
    &:hover {
        transform: scale(1.1);
        cursor: pointer;
    }
`