import React, { useState } from 'react';
import styled from 'styled-components';
import { COLOR_BRAND } from '../styling/styles';

export default function MenuButton({ toggleMenu, menuBurger, menuState }: { toggleMenu: () => void, menuBurger : () => void, menuState: boolean}) {
    const [color, setColor] = useState<string>(COLOR_BRAND)

    function toggle() {
        toggleMenu()
        menuBurger()
    }

    function enter() {
        setColor('white')
    }

    function exit() {
        setColor(COLOR_BRAND)
    }

    return (
        <BurgerMenu onClick={toggle} onMouseEnter={enter} onMouseLeave={exit}>
            <BurgerMenuBars color={color} isOpen={menuState} />
            {/* <BurgerMenu_Bars color={color} isOpen={location.pathname == '/userprofile' ? !menuState : menuState} /> */}
        </BurgerMenu>
    )
}

const BurgerMenu = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    margin: 40px 36px;
    min-height: 24px;
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    &:focus{
        outline: none;
    }
`

const BurgerMenuBars = styled.i<{ isOpen: boolean, color: string }>`
    display: block;
    position: relative;
    width: 30px;
    height: 3px;
    background-color: ${({ color }) => color};
    transition: transform 0.525s ease 0.225s;
    &:hover{
        background-color: white;
    }
    &:before, &:after{
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: inherit;
        transition: inherit
    }
    &:before {
        transform: ${({ isOpen }) => (
        isOpen ? 'translate(8.5px, -6.5px) rotate(45deg) scaleX(0.7)' : 'translate(0, -12px)'
    )};
    }
    &:after {
        transform: ${({ isOpen }) => (
        isOpen ? 'translate(8.5px, 6.5px) rotate(-45deg) scaleX(0.7)' : 'translate(0, 12px)'
    )};
    }
`