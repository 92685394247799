import React from 'react';
import { useLocation } from 'react-router-dom';
import { BaseCard } from '../../Card';
import { Article } from '../Article';
import { GscholarArticle } from '../GscholarArticle';
import MedscapeArticleCard from './MedscapeArticleCard';
import PubmedArticleCard from './PubmedArticleCard';
import RadiopaediaArticleCard from './RadiopaediaArticleCard';

type Props = {
    document: Article;
    index: number;
};
export default function ArticleCard(props: Props) {
    const { document, index } = props;
    const { Heading, Source, Blurb } = ArticleSwitch(document);
    const location = useLocation();
    return (
        <div>
            <BaseCard
                document={document}
                index={index}
                Heading={Heading}
                Source={Source}
                Blurb={Blurb}
                location={location}
                type="Article"
            />
        </div>
    );
}

function ArticleSwitch(document: Article) {
    switch (document._index) {
        case 'pubmed':
            return PubmedArticleCard(document);
        case 'medscape':
            return MedscapeArticleCard(document);
        case 'medscape_ed':
            return MedscapeArticleCard(document);
        case 'radiopedia_article':
            return RadiopaediaArticleCard(document);
        default:
            throw new Error('Not Implemented')
    }
}

function GscholarArticleCard(document: GscholarArticle) {
    throw new Error('Function not implemented.');
}
