import { ParsedQuery } from 'query-string';
import React from 'react';
import Header from '../Header';
import { Filters } from '../menu/MenuBundles';
import { ContentType, DeleteDirection, SearchMode, SortMode } from '../RemoteAPI';
import { SearchResults } from '../results/SearchResults';
import { isFuzzyDirection, isSearchMode, isSortMode } from '../util';

export function Query() {
    
    return (
        <>
        <Header />
        <SearchResults />
        </>
    )
}

function getContentType(type: string): ContentType{
    switch(type){
        case 'article':
            return 'article'
        case 'case':
            return 'case'
        case 'mixed':
            return 'mixed'
        case 'images':
            return 'images'
        case 'guideline':
            return 'guideline'
        default:
            return 'mixed'
    }
}

export const sanitizedParams = (params: ParsedQuery<string | number>) => {
    const {page, size, query, category, source, modality, type, mode, direction, sort} = params;

    const _page = typeof page === 'number' ? page : 0
    const _query = typeof query === 'string' ? query : '';
    const _contentType = typeof type === 'string' ? getContentType(type) : 'mixed';
    const _size = typeof size === 'number' ? size : 20;
    const _category = parseFilterParam(category) as Filters['categories'];
    const _source = parseFilterParam(source) as Filters['datasources'];
    const _modality = parseFilterParam(modality) as Filters['modality'];
    const _filters = {
        categories: _category,
        datasources: _source,
        modality: _modality
    }
    const _mode = typeof mode === 'string' && isSearchMode(mode) ? mode as SearchMode : 'fuzzy'
    const _direction = typeof direction === 'string' && isFuzzyDirection(direction) ? direction as DeleteDirection : 'left' 
    const _sortMode = typeof sort === 'string' && isSortMode(sort) ? sort as SortMode : 'completeness'
    return {
        _query,
        _size,
        _page,
        _contentType,
        _filters,
        _mode,
        _direction,
        _sortMode
    }
}

// function updateFilters(filter: Filters['categories'], filters: Filters, key: 'categories'): Filters['categories']
// function updateFilters(filter: Filters['datasources'], filters: Filters, key: 'datasources'): Filters['datasources']
// function updateFilters(filter: Filters['modality'], filters: Filters, key: 'modality'): Filters['modality']
// function updateFilters<K extends keyof Filters>(filter: Filters[K], filters: Filters, key: K){
//     Array.isArray(filter) ? filters[key] = filter.map(f => String(f)) : filters[key].push(String(filter))
// }

const parseFilterParam = (param: string | number | (string | number)[] | null | undefined) => {
    if(!param) 
        return [];
    const parsed = Array.isArray(param) ? param.map(x => String(x)) : [String(param)];
    return parsed;
}
