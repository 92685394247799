import { Anchor, Heading } from 'grommet';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getHomeType, setHome, setToggleMode, Store } from '../data-store';
import { BG_LAYER_0 } from '../styling/styles';
import { FilterMenu, MenuBase } from './MenuBundles';
import MenuButton from './MenuButton';
import { KeycloakInstance } from 'keycloak-js';


type SidebarProps = {
    header?: string
    logout: () => void
    defaultOpen?: boolean
}

export default function Sidebar(props: SidebarProps){
    const {header, logout, defaultOpen} = props
    const [open, setOpen] = useState(defaultOpen? true : false)
    const [menuState, setMenuState] = useState<MenuStates>('base')
    const history = useHistory()
    const location = useLocation()
    const [menuBurger, setMenuBurger] = useState<boolean>(false)



    const homeType = useSelector((state: Store) => getHomeType(state))
    const dispatch = useDispatch()
    const toggleMode = homeType === "continuous_learning"? true : false

        
    function onBookmarkCheck () {
        dispatch(setToggleMode(toggleMode))
        switch (homeType) {
            case "continuous_learning":
                if(location.pathname.includes('continuous_learning')){
                    history.push('/reading_session')
                }
                else{
                    dispatch(setHome('reading_session'))
                }
                break;
            case "reading_session":
                    if(location.pathname.includes('reading_session')){
                        history.push('/continuous_learning')
                    }
                    else{
                        dispatch(setHome('continuous_learning'))
                    }
                    break;
            default:
                break;
        }
    }
    const {state, display} = menuNavMap[menuState]
    return(
            <>
                <MenuButton toggleMenu={() => setOpen(!open)} menuBurger={() => setMenuBurger(!menuBurger)} menuState={menuBurger}/>
                <Drawer isOpen={open}>
                    <Container>
                        <Heading size="small" margin={{top: '18px', bottom: '0'}} style={{lineHeight: '56px'}}>{header}</Heading>
                        <Anchor 
                            onClick={() => setMenuState(state)} 
                            as="div" 
                            margin={{vertical: "small"}}
                            style={{width: "fit-content"}}
                        >
                            {display}
                        </Anchor>
                        { menuState === 'advanced_search' && <FilterMenu />}
                        <MenuBase 
                            setOpen={setOpen}
                            logout={logout}
                            bookmarkChecked={toggleMode}
                            onBookmarkCheck={onBookmarkCheck}
                            setMenuBurger={setMenuBurger}
                        />
                    </Container>
                </Drawer>
            </> 
    )
}

export type MenuStates = 'base' | 'advanced_search'

type MenuNav = {
    [key in MenuStates]: {
        display: string,
        state: MenuStates
    }
}

const menuNavMap: MenuNav = {
    'base': {
        display: 'Advanced Search', 
        state: 'advanced_search'
    },
    'advanced_search': {
        display: 'Back', 
        state: 'base'
    }
}

const Drawer = styled.div<{isOpen: boolean}>`
    position: absolute;
    z-index: 5;
    height: 100%;
    top: 0;
    right: 0;
    padding: ${({isOpen}) => isOpen ? '0 12px' : '0px'};
    right: 0;
    top: 0;
    width: ${({isOpen}) => isOpen ? '250px' : '0px'};
    overflow-x: hidden;
    transition: width 0.75s, border 0.75s;
    background-color: ${BG_LAYER_0};
    border-left: ${({isOpen}) => isOpen ? '1px solid rgba(255, 255, 255, 0.33)' : '0px solid rgba(255, 255, 255, 0.33)'};
`

const Container = styled.div`
    width: 225px;
    padding: 6px;
    overflow-x: hidden;
    overflow-y: auto;
`