import React, { useEffect, useState } from 'react';
import Dashboard from '../Dashboard';
import { useDispatch } from 'react-redux';
import { setHome } from '../data-store';
import { useHistory } from 'react-router';
import { fetchProfile } from "../RemoteAPI";
import Loading from '../Loading';
import { Box } from 'grommet';
import { setAuntminnieAuth } from '../data-store';



export function ContinuousLearning() {
    const history = useHistory()
    const dispatch = useDispatch()
    const uid = localStorage.getItem('login')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 750);
        dispatch(setHome('continuous_learning'))
        if (uid) {
            fetchProfile(uid).then((data) => {
                if (data === 'user not found') {
                    history.push('/userprofile')
                } else {
                    dispatch(setAuntminnieAuth(data['Auntminnie_auth_successful']))
                }

            })
        }
        return () => clearTimeout(timer);
    }, [])

    return (
        <>{loading ? <Box fill justify="center" align="center" margin={{ vertical: '12%' }}> <Loading size="200px" /> </Box> :
            <Dashboard
                mode='continuous_learning'
            />}

        </>
    );
}