import { Anchor } from "grommet";
import React, { useState } from 'react';
import styled from 'styled-components';
import { report_broken_link } from '../RemoteAPI';

export default function ReportBroken({index, id, url} : {index: string, id: string, url: string}){
    const [reported, setReported] = useState(false)

    function report() {
        !reported && report_broken_link(index, id, url)
        setReported(true)
    }
    return(
        <Report onClick={report} disable={reported}>{reported ? 'Reported' : 'Report broken link'}</Report>
    )
}

export const Report = styled(Anchor)<{disable: boolean}>`
    font-size: 13px;
    color: #666666;
    &:hover{
        text-decoration: ${ ({disable}) => disable ? 'none' : 'underline'};
        cursor: ${ ({disable}) => disable ? 'default' : 'pointer'};
    }
`