import React from 'react'
import { Heading, Text, Anchor } from 'grommet';
import { Row, Title, BaseCase, Frame } from './Case';
import Magnifier from 'react-magnifier';

export interface RadiopaediaCase extends BaseCase {
    _index: 'radiopedia'
    _source: {
        "age": number;
        "popularity_score": number;
        "authors": string;
        "date": string;
        "description": string;
        "diagnostic certainty": string;
        "discussion": string;
        "gender": string;
        "image": string;
        "modality": string;
        "search completeness": string;
        "tag urls": string | null;
        "tags": string | null;
        "title": string;
        "url": string;
    }
}

export default function Radiopaedia(document: RadiopaediaCase) {
    const {
        title,
        url,
        authors,
        description: text,
        discussion,
        image: image_src,
        date: publication_date,
        age,
        gender,
        "diagnostic certainty": certainty,
        modality,
        tags,
        "tag urls": tag_urls,
    } = document._source
    const index = document._index

    return {
        Left: (
            <>
                <Title text={title} url={url} index={index} />
                <Heading level="4">{authors}</Heading>
                <Text margin="xsmall">{text}</Text>
                <Text margin="xsmall">{discussion}</Text>
                <Frame>          
                    <Magnifier src={image_src} zoomImgSrc={image_src} mgShape="square" mgWidth={185} zoomFactor={1.25} mgHeight={185} mgShowOverflow={false} />
                </Frame>
            </>
        ),
        Right: (
            <>
                <Row label="Author(s):" field={authors} />
                <Row label="Date:" field={publication_date} />
                <Row label="Patient Age:" field={age} />
                <Row label="Patient Gender:" field={gender} />
                <Row label="Diagnostic Certainty:" field={certainty} />
                <Row label="Image Types:" field={modality} />
                {(tags && tag_urls) && <Row label="Tags" field={<TagArray tags={tags.split(',')} tag_urls={tag_urls.split(',')} />} />}
            </>
        ),
        Title: text
    }
}

type TagArrayProps = {
    tags: string[];
    tag_urls: string[];
}
function TagArray({ tags, tag_urls }: TagArrayProps) {
    return <>{tags.map((tag, i, tags) => Tag(tag, i, tags, tag_urls[i]))}</>
}

function Tag(tag: string, index: number, tags: string[], url: string) {
    const length = tags.length
    return (
        <>
            <Anchor key={index} size="medium" onClick={() => window.open(url)}>{tag}</Anchor>
            <Text size="medium">{index < (length - 1) && ','}&nbsp;</Text>
        </>
    )
}