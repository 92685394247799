import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getSearchParams, setLoading, Store } from './data-store';
import {
    buildParameterList,
    ContentType,
    DeleteDirection,
    fetchBookmarks,
    fetchPopular,
    SearchMode,
    SortMode,
    fetchSystemSelected,
    fetchGuidelines,
} from './RemoteAPI';
import { Results } from './results/SearchResults';

export function useSearch() {
    const history = useHistory();
    const params = useSelector((state: Store) => getSearchParams(state));
    const {
        query,
        contentType,
        pageSize,
        filters,
        searchMode,
        page,
        deleteDirection,
        sortMode,
    } = params;
    const dispatch = useDispatch();
    const location = useLocation();

    function search() {
        if (query.length < 1 && filters.categories.length < 1) return;
        const newUrl = makeSearchUrl(
            page,
            pageSize,
            query,
            filters,
            contentType,
            searchMode,
            deleteDirection,
            sortMode
        );
        if (location.pathname + location.search !== newUrl) {
            history.push(newUrl);
            dispatch(setLoading());
        }
    }

    return search;
}

export function useGuideline() {
    useSearch();
    function search(){
        const guideline : string  = "Bi-RADS"
        return fetchGuidelines(guideline)
        }
    return search;
}

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const makeSearchUrl = (
    page: number,
    pageSize: number,
    query: string,
    filters: any,
    contentType: ContentType,
    searchMode: SearchMode,
    deleteDirection: DeleteDirection,
    sortMode: SortMode
) => {
    const catagoryParams = buildParameterList(filters.categories, 'category');
    const modalityParams = buildParameterList(filters.modality, 'modality');
    const datasourceParams = buildParameterList(filters.datasources, 'source');

    return `/search?page=${page}&size=${pageSize}&query=${query}${catagoryParams}${modalityParams}${datasourceParams}&type=${contentType}&mode=${searchMode}`;
};

export type DataType = 'popular' | 'recommended' | 'bookmark';
// export type DataType = 'popular' | 'bookmark';

// todo get rid of anys
export function useData(
    type: DataType,
    size: number
): [Results, boolean, any, any] {
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        dataDispatch(type, size)
            .then((results: any) => {
                setData(results);
                // console.log(results)
                setLoading(false);
            })
            .catch((error: any) => {
                setError(error);
                setLoading(false);
            });
    }, []);

    return [data, loading, error, setData];
}

const dataDispatch = (type: DataType, size: number) => {
    switch (type) {
        case 'popular':
            return fetchPopular(size);
        // case 'recommended': return fetchRecommended(size);
        case 'recommended':
            return fetchSystemSelected(size);
        case 'bookmark':
            return fetchBookmarks(size);
    }
};

export function useTraceUpdate(props: any) {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {} as any);
        if (Object.keys(changedProps).length > 0) {
            // console.log('Changed props:', changedProps);
        }
        prev.current = props;
    });
}

export function isSearchMode(mode: string) {
    if (mode === 'exact' || mode === 'fuzzy') return true;
    return false;
}

export function isFuzzyDirection(deleteDirection: string) {
    if (deleteDirection === 'left' || deleteDirection === 'right') return true;
    return false;
}

export function isSortMode(sortMode: string) {
    if (sortMode === 'elastic' || sortMode === 'completeness') return true;
    return false;
}
