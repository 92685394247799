import React from "react";
import { Box, Button } from "grommet";

type UnauthProps ={
    logout: () => void
}


export default function Unauthorized(props: UnauthProps) {
  const { logout } = props;
  const status = localStorage.getItem('status')

  const handleSilentLogout=() => {
    // console.log('here')
    window.open(' ','_parent','');
    window.close();
}
  return (
    <div>
      <Box align="center" pad="medium">
        <Box align="center" border="all" pad="medium">
          <h1>401 Unauthorized</h1>
          <p>
            Sorry, you are not authorized to access this page. Please contact
            your admin.
          </p>
          <Button
            margin="medium"
            style={{ width: "50%" }}
            primary
            label="Home"
            onClick={status === 'SSO'? handleSilentLogout: logout}
          />
        </Box>
      </Box>
    </div>
  );
}
