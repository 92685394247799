import { Box, Button, CheckBox, Form, Heading, Select, Text, TextInput } from 'grommet';
import React, { useEffect, MouseEvent } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { datasourceName } from '../menu/MenuBundles';
import { createProfile, fetchProfile, updateProfile, validateAuntminnie } from "../RemoteAPI";
import { BG_LAYER_0 } from '../styling/styles';
import Loading from "../Loading";
import { useDispatch } from 'react-redux';
import { setAuntminnieAuth } from '../data-store';


let Mongo_datasourceName: string[] = []

datasourceName.forEach(element => {
	Mongo_datasourceName.push(element.replace('.', '_')) // Mongo cannot have dots in keys, . replaced by _
});

function useAsyncState(initialValue: any) {
	const [value, setValue] = React.useState(initialValue);
	const setter = (x: string) =>
		new Promise(resolve => {
			setValue(x);
			resolve(x);
		});
	return [value, setter];
}

export default function UserForm(props:any) {
	const { userInfo, loggedIn } = props
	const [response, setResponse] = React.useState('');
	const [responseAunt, setResponseAunt] = React.useState('');
	const [formValidatorLoader, setformValidatorLoader] = React.useState(false);
	const [disableFields, setdisableFields] = React.useState(false);
	const [profile, setProfile] = useAsyncState({ user_id: userInfo.preferred_username, email: userInfo.email , language: 'English', Auntminnie_auth_successful: false });
	const history = useHistory()
	const dispatch = useDispatch()

	const updateAuntminnieAuth = (newValue: boolean) => dispatch(setAuntminnieAuth(newValue))
	updateAuntminnieAuth(profile['Auntminnie_auth_successful'])

	useEffect(() => {
		fetchProfile(userInfo.preferred_username).then((data) => {
			if (data === 'user not found') {
				setResponse(data)
			} else {
				setProfile(data)
				setdisableFields(true)
			}
		})
	}, []);

	const handleChangeProfile = (event: React.ChangeEvent<HTMLInputElement>) => {

		if (event.target.type === 'checkbox') {
			setProfile({
				...profile,
				[event.target.name]: event.target.checked,
				Auntminnie_auth_successful:false
			});

		}
		else {
			setProfile({
				...profile,
				[event.target.name]: event.target.value
			});
		}

	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		setformValidatorLoader(true)
		if (!profile['Auntminnie_auth_successful'] && profile['Auntminnie_checkbox']) {
			validateAuntminnie(profile['AuntMinnie_com_user'], profile['AuntMinnie_com_pass']).then((data) => {
				setResponseAunt(data)
				if (data === 'Verified') {
					setformValidatorLoader(false)
					setProfile({
						...profile,
						Auntminnie_auth_successful: true
					}).then((profile: Object) => {
						if (response === 'user not found') {
							createProfile(profile).then((data) => setResponse(data))
						}
						else {
							updateProfile(profile).then((data) => setResponse(data))
						}
					})
				}
				else {
					setformValidatorLoader(false)
				}

			})
		}
		else {
			setProfile({
				...profile,
			}).then((profile: Object) => {
				if (response === 'user not found') {
					createProfile(profile).then((data) => setResponse(data))
				}
				else {
					updateProfile(profile).then((data) => setResponse(data))
				}
			})
			setformValidatorLoader(false)

		}
	}

	const handleRevalidate = (event: any) => {
		setProfile({
			...profile,
			Auntminnie_auth_successful: false,
		})
		if (!event.target.checked) {
			profile[`AuntMinnie_com_user`] = ''
			profile[`AuntMinnie_com_pass`] = ''
		}

	};



	return (
		<>
			<Box fill style={{ backgroundColor: BG_LAYER_0 }} >
				{formValidatorLoader ? <Box fill justify="center" align="center" margin={{ vertical: '12%' }}> <Loading size="200px" /> </Box> :
					<Box animation={{
						type: "fadeIn",
						delay: 500,
						duration: 2500,
						size: "large"
					  }} margin={{ top: 'small', horizontal: '16%' }}>
						<Box direction="column" justify="between" align={response === 'user updated' ? "center" : "start"} pad="medium" border={response !== 'user updated' && 'bottom'}>
							<Heading level={3} margin={{ bottom: '0', top: '0' }}>
								{response === 'user updated' ? <Box align="center" margin={{ top: '60%' }} border="all" pad="medium">Profile Updated Successfully ! <Button
									margin="medium"
									style={{ width: '50%' }}
									primary
									label="Continue"
									onClick={() => {
										history.push(`/`);
									}}
								/> </Box> : response === 'user not found' ? <>Create User Profile</> : <>User Profile</>}
							</Heading>
						</Box>
						{response !== "user updated" && <Box
							direction="column"
							justify="between"
							align="start"
							pad="small"
							margin={{ horizontal: 'small' }}
							overflow={{ vertical: 'auto', horizontal: 'hidden' }}
						>
							<Box fill pad="small" direction="row" justify='between'>
								<Text size="large" weight="bold">
									Personal Information
							</Text>
								{response !== 'user not found' && <>
									{disableFields && <Button
										alignSelf="center"
										style={{ width: '20%' }}
										primary
										label={'Continue'}
										onClick={() => history.push(`/`)}
									/>}
									<Button
										alignSelf="center"
										style={{ width: '20%' }}
										primary
										label={'Edit Profile'}
										onClick={() => setdisableFields(false)}
									/>
								</>}
							</Box>
							<Box fill border="bottom" margin={{ top: 'small' }} />
							<Form style={{ width: '100%' }} onSubmit={handleSubmit} >
								<Box direction="column">
									<StyledOuterBox margin={{ top: 'small' }}>
										<Text margin="small">Email : </Text>
										<StyledBox>
											<StyledTextInput
												value={profile["email"]}
												placeholder="example@realtimemedical.com"
												name="email"
												onChange={handleChangeProfile}
												disabled={disableFields}
											/>
										</StyledBox>
									</StyledOuterBox>
									<StyledOuterBox>
										<Text margin="small">Login id : </Text>
										<StyledBox>
											<StyledTextInput
												value={profile["user_id"]}
												placeholder="jonas.salk or your email"
												name="user_id"
												onChange={handleChangeProfile}
												disabled={true}
											/>
										</StyledBox>
									</StyledOuterBox>
									<StyledOuterBox>
										<Text margin="small">Display Name *: </Text>
										<StyledBox>
											<StyledTextInput
												value={profile["display_name"]}
												placeholder="Dr. First and Last Name (ex. Dr. Jonas Salk)"
												name="display_name"
												onChange={handleChangeProfile}
												required
												disabled={disableFields}
											/>
										</StyledBox>
									</StyledOuterBox>

									<StyledOuterBox>
										<Text margin="small">Mobile tel *: </Text>
										<StyledBox>
											<StyledTextInput
												value={profile["contact_num"]}
												name="contact_num"
												type="tel"
												required
												//pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$"
												//placeholder="Format : +1 123 456 7890"
												onChange={handleChangeProfile}
												disabled={disableFields}
											/>
										</StyledBox>
									</StyledOuterBox>

									{/* <StyledOuterBox>
										<Text margin="small">Language : </Text>
										<StyledBox>
											<Select
												disabled={disableFields}
												options={['English']}
												value={profile['language']}
												name="language"
												onChange={({ option }) => setProfile({
													...profile,
													['language']: option
												})}
											/>
										</StyledBox>
									</StyledOuterBox> */}

									{/* <StyledOuterBox>
										<Text margin="small">Password : </Text>
										<Button
											margin={{ top: 'small' }}
											alignSelf="center"
											style={{ width: '50%' }}
											primary
											label="Reset Password"
											disabled={disableFields}
										/>
									</StyledOuterBox> */}
									<Box>
										<Text margin="xsmall">* - required </Text>
									</Box>

									<Box fill border="horizontal" pad="small" margin={{ top: 'small', bottom: 'small' }}>
										<Text size="large" weight="bold">
											Data Source Credentials
								</Text>
									</Box>
									<Box>
										<StyledOuterBox fill margin="xsmall" border="bottom">
											{/* <Text margin="small">AuntMinnie.com: </Text> */}
											<CheckBox
												checked={profile['Auntminnie_checkbox']}
												name={'Auntminnie_checkbox'}
												label={'Auntminnie:'}
												type="checkbox"
												onChange={handleChangeProfile}
												onClick={handleRevalidate}
												disabled={disableFields}
											/>
											<Box
												direction="row"
												justify="evenly"
												width="80%"
											>
												<StyledBoxSource>
													<StyledTextInput
														value={profile[`AuntMinnie_com_user`]}
														name={`AuntMinnie_com_user`}
														placeholder="Username"
														required={profile['Auntminnie_checkbox']}
														onChange={handleChangeProfile}
														disabled={disableFields}
														onClick={handleRevalidate}

													/>
												</StyledBoxSource>
												<StyledBoxSource>
													<StyledTextInput
														value={profile[`AuntMinnie_com_pass`]}
														name={`AuntMinnie_com_pass`}
														placeholder="Password"
														type='password'
														required={profile['Auntminnie_checkbox']}
														onChange={handleChangeProfile}
														disabled={disableFields}
														onClick={handleRevalidate}

													/>
												</StyledBoxSource>
											</Box>
										</StyledOuterBox>
										{responseAunt === 'Failed' && <Text margin="small" color="firebrick" textAlign="end">
											Auntminnie Authentication Failed! Try Again or Uncheck the Datasource.
											</Text>}
									</Box>

									{/* {Mongo_datasourceName.map((item, idx) => {                     
										return (
											<StyledOuterBox margin="xsmall" key={idx} border="bottom">
												<CheckBox
													checked={profile[item]}
													name={item}
													label={item.replace('_', '.')}
													type="checkbox"
													onChange={handleChangeProfile}
												/>
												{item === 'AuntMinnie_com' && (
													<Box
														direction="row"
														justify="evenly"
														width="70%"
														margin={{ left: 'large' }}
													>
														<StyledBoxSource>
															<StyledTextInput
																value={profile[`${item}_user`]}
																name={`${item}_user`}
																placeholder="Username"
																required={profile['AuntMinnie_com'] ? true : false}
																onChange={handleChangeProfile}
																disabled={disableFields}
															/>
														</StyledBoxSource>
														<StyledBoxSource>
															<StyledTextInput
																value={profile[`${item}_pass`]}
																name={`${item}_pass`}
																placeholder="Password"
																type='password'
																required={profile['AuntMinnie_com'] ? true : false}
																onChange={handleChangeProfile}
																disabled={disableFields}
															/>
														</StyledBoxSource>
													</Box>
												)}
											</StyledOuterBox>
										);
									})} */}

									<Button
										margin={{ top: 'small' }}
										alignSelf="center"
										style={{ width: '20%' }}
										primary
										type='submit'
										name={response !== 'user not found' ? 'New' : 'existing'}
										label={response !== 'user not found' ? 'Update Changes' : 'Save'}
										disabled={disableFields}
									/>
								</Box>
							</Form>
						</Box>}
					</Box>}
			</Box>
		</>
	);
}

const StyledBox = styled(Box)`
height: 98%;
width: 50%;
background-color: #202020;
border-radius: 5px;
border: 0;
`;

const StyledBoxSource = styled(Box)`
height: 98%;
width: 35%;
background-color: #202020;
border-radius: 5px;

`;

const StyledTextInput = styled(TextInput)`
border: 0;
`;
const StyledOuterBox = styled(Box)`
display: flex;
flex-direction: row;
padding: 5px;
justify-content: space-between;
`;
