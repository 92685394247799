import { Text } from "grommet";
import React from "react";
import { BaseArticle, Title, Row } from "./Article";

export interface RadiopaediaArticle extends BaseArticle {
  _index: "radiopedia_article";
  _source: {
    description: string;
    discussion: string;
    popularity_score: number;
    title: string;
    url: string;
    authors: string;
  };
}

export default function Radiopaedia_Article(document: RadiopaediaArticle) {
  const { description, discussion, title, url, authors } = document._source;
  return {
    Left: (
      <>
        <Title text={title} url={url} />
        {/* <Text margin="xsmall">{description}</Text> */}
        <Text margin="xsmall">{discussion}</Text>
      </>
    ),
    Right: (
      <>
        <Row label="Author(s):" field={AuthorArray(authors)} />
      </>
    ),
    Title: title,
  };
}

function AuthorArray(authors: string) {
  let scrape = authors.split("◉").join("");
  let scrape_symbol = scrape.split("◈").join("");
  return scrape_symbol;
}
