import React from 'react';
import styled from 'styled-components'

type ToggleSwitchProps = {
  onClick: () => void, 
  state: boolean, 
  monocolor?: boolean, 
  disabled?: boolean
}
export default function ToggleSwitch({onClick, state, monocolor, disabled}: ToggleSwitchProps) {
    return(
        <>
        <Container onClick={() => !disabled && onClick()} disabled={disabled}>
          {monocolor ? <SwitchMonocolour state={state} disabled={disabled}/> : <Switch state={state} disabled={disabled}/>}
        </Container>
        </>
    )
}

const Container = styled.span<{disabled: undefined | boolean}>`
  display: block;
  background-color: #333333;
  width: 55px;
  min-width: 55px;
  height: 20px;
  border-radius: 30px;
  padding: 4px 0;
  border: 0;
  margin-right: 0;
  &:hover{
    cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
  }
`

const Switch = styled.span<{state: boolean, disabled: undefined | boolean}>`
  transition: margin-left 0.5s, background-color 0.5s;
  margin-left: ${({state}) => state ? '32px' : '3px'};
  background-color: ${({state, disabled}) => state && !disabled ? '#007bff' : '#656565'};
  display: inline-block;
  border-radius: 50px;
  width: 20px;
  height: 20px;
`

const SwitchMonocolour = styled.span<{state: boolean, disabled: undefined | boolean}>`
  transition: margin-left 0.5s, background-color 0.5s;
  margin-left: ${({state}) => state ? '32px' : '3px'};
  background-color: ${({disabled}) => !disabled ? '#007bff' : '#656565'};
  display: inline-block;
  border-radius: 50px;
  width: 20px;
  height: 20px;
` 