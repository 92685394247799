import { Box, Tab, Tabs } from 'grommet';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Boxes from './ContentBoxes';
import {
    getContentType,
    setContentType,
    Store,
    getAuntminnieAuth,
    setFilter,
} from './data-store';
import Dropdown from './Dropdown';
import ke_logo from './images/knowledge_enable_logos_ke_nighttime_logo_x395_y55.png';
import {
    contentTypeFromTab,
    TabEnum,
    tabFromContentType,
} from './results/SearchResults';
import SearchBar from './SearchBar';
import { Filters } from './menu/MenuBundles';

export type Modes = 'continuous_learning' | 'reading_session';
export type BoxType = 'Bookmarks ' | 'Peer Selected' | 'System Selected';

export type DashboardProps = {
    mode: Modes;
};

export default function Dashboard({ mode }: DashboardProps) {
    const dispatch = useDispatch();
    const auntminnieAuth = useSelector((state: Store) =>
        getAuntminnieAuth(state)
    );
    const updateFilter = (newFilters: Filters) =>
        dispatch(setFilter(newFilters));
    useEffect(() => {
        if (auntminnieAuth) {
            updateFilter({
                categories: [],
                datasources: [
                    'auntminnie',
                    'radiopedia',
                    'radiopedia_article',
                    'pubmed',
                    'medscape',
                ],
                modality: [],
            });
        } else {
            updateFilter({
                categories: [],
                datasources: [
                    'radiopedia',
                    'radiopedia_article',
                    'pubmed',
                    'medscape',
                ],
                modality: [],
            });
        }
    }, []);

    return (
        <Box fill justify="center" align="center">
            <CenterSearch mode={mode} />
        </Box>
    );
}

function CenterSearch({ mode }: DashboardProps) {
    const dispatch = useDispatch();
    const contentType = useSelector((state: Store) => getContentType(state));
    const tab = tabFromContentType[contentType];
    const auntminnieAuth = useSelector((state: Store) =>
        getAuntminnieAuth(state)
    );

    function updateTab(tab: TabEnum) {
        dispatch(setContentType(contentTypeFromTab[tab]));
    }

    return (
        <Box
            direction="column"
            justify="center"
            align="center"
            gap="small"
            pad={{ vertical: '2ch', horizontal: '6ch' }}
            style={{ minWidth: '60%' }}
        >
            <img src={ke_logo} alt="KnowledgeEnable" />
            <SearchBar style={{ margin: '0 auto', width: '100%' }} />
            <Box direction="row" width="100%" margin={{ bottom: '16px' }}>
                <Tabs activeIndex={tab} onActive={updateTab}>
                    <Tab title="All Results" />
                    <Tab title="Articles" />
                    <Tab title="Cases" />
                    <Tab title="Images" />
                    <Tab style={{ marginRight: '0px' }} title="Guidelines" />
                    <Dropdown />
                </Tabs>
            </Box>
            {mode === 'continuous_learning' && <Boxes />}
        </Box>
    );
}
