import { Action } from "./actions";
import { ContentType, SearchMode, DeleteDirection, SortMode } from "../RemoteAPI";
import { Filters } from "../menu/MenuBundles";
import { SearchResult } from "../results/SearchResults";

const defaultFilters: Filters = {
    categories: [],
    datasources: [],
    modality: [],
}

const initState: Store = {
    home: 'continuous_learning',
    query: '',
    contentType: 'mixed',
    page: 0,
    pageSize: 20,
    filters: defaultFilters,
    searchMode: 'fuzzy',
    searchResult: null,
    loading: false,
    toggleMode: true,
    deleteDirection: 'left',
    sortMode: 'completeness',
    auntminnieAuth: false
}

export type Store = {
    home: HomePage
    query: string,
    contentType: ContentType,
    pageSize: number,
    page: number,
    filters: Filters,
    searchMode: SearchMode
    searchResult: SearchResult | null,
    loading: boolean,
    toggleMode : boolean,
    deleteDirection: DeleteDirection,
    sortMode: SortMode,
    auntminnieAuth: boolean
} 

export const searchReducer = (state=initState, action: Action) => {
    switch(action.type) {
        case 'SET_HOME': {
            const {page} = action;
            return {
                ...state,
                home: page
            }
        }

        case 'SET_AUNTMINNIE_AUTH': {
            const { param } = action;
            return {
                ...state,
                auntminnieAuth: param
            }
        }

        case 'SET_TOGGLE_MODE': {
            const {toggleMode} = action;
            return {
                ...state,
                toggleMode
            }
        }

        case 'SET_DELETE_DIRECTION': {
            const {deleteDirection} = action;
            return {
                ...state,
                deleteDirection
            }
        }

        case 'SET_SORT_MODE': {
            const {sortMode} = action;
            return {
                ...state,
                sortMode
            }
        }

        case 'SET_QUERY': {
            const {query} = action;
            return {
                ...state,
                query
            }
        }
        case 'SET_PAGE': {
            const {page} = action;
            return {
                ...state,
                page
            }
        }
        case 'SET_PAGE_SIZE': {
            const {pageSize} = action;
            return {
                ...state,
                pageSize
            }
        }
        case 'SET_CONTENT_TYPE': {
            const {contentType} = action;
            return {
                ...state,
                contentType
            }
        }
        case 'SET_SEARCH_PARAMS': {
            const {query, contentType, pageSize, page,  filters} = action;
            const _query = query ? query : state.query;
            const _contentType = contentType ? contentType : state.contentType;
            const _pageSize = (pageSize !== undefined) ? pageSize : state.pageSize;
            const _page = (page !== undefined) ? page : state.page;
            const _filters = filters ? filters : state.filters;
            return {
                ...state,
                query: _query,
                contentType: _contentType,
                pageSize: _pageSize,
                page: _page,
                filters: _filters
            }
        }
        case 'SET_FILTER': {
            const {filters} = action;
            return {
                ...state,
                filters
            }
        }
        case 'SET_SEARCH_MODE': {
            const {mode} = action
            return {
                ...state,
                searchMode: mode
            }
        }
        case 'SET_RESULTS': {
            const {result} = action;
            return {
                ...state,
                searchResult: result,
                loading: false
            }
        }
        case 'SET_LOADING': {
            return {
                ...state,
                loading: true
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}

export type HomePage = 'continuous_learning' | 'reading_session'