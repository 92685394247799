import React from "react";
import { Anchor, Box, Text } from "grommet";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BookmarkButton } from "../Bookmarks";
import LikeButton, { update_score } from "../LikeButton";
import ReportBroken from "./ReportBroken";
import { Guideline } from "./SearchResults";
import { Article } from "./articles/Article";
import { Case } from "./cases/Case";

type CardP = {
    document: Article | Case | Guideline,
    index: number,
    Heading: string,
    Source: string,
    Blurb: JSX.Element,
    location: any,
    type: "Article" | "Case" | "Guideline"
}

export function BaseCard({
  document,
  index,
  Heading,
  Source,
  Blurb,
  location,
  type,
}: CardP) {
  return (
    <Box direction="row" gap="small" border="bottom">
      <Box direction="column" justify="center" align="center" gap="
medium">
        <LikeButton
          id={document._id}
          index={document._index}
          score={document._source.popularity_score}
          direction="column"
          liked={document._liked}
          onLike={(delta) => update_score(document, delta)}
        />
        <BookmarkButton id={document._id} bookmarked={document._bookmarked} />
      </Box>
      <Box
        fill
        direction="column"
        gap="xsmall"
        pad={{ left: "small", top: "small", bottom: "xxsmall" }}
      >
        <StyledLink
          to={{
            pathname: `/content/${document._id}`,
            state: { background: location, index, document },
          }}
        >
          <Header>
            [{type}] {Heading}
          </Header>
        </StyledLink>
        <StyledSource onClick={() => openLink(Source)}>{Source}</StyledSource>
        <StyledBlurb>{Blurb}</StyledBlurb>
        <Box direction="row" justify="end">
          <ReportBroken
            index={document._index}
            id={document._id}
            url={document._source.url}
          />
        </Box>
      </Box>
    </Box>
  );
}

function openLink(url: string) {
  window.open(url);
}

export const StyledLink = styled(Link)`
  text-decoration: none;
  width: fit-content;
  &:hover {
    text-decoration: underline;
  }
  &:active {
  }
`;

export const Report = styled(Anchor)`
  font-size: 13px;
  color: #666666;
`;

export const Header = styled.h2`
  margin: 0;
  line-height: 28px;
`;

export const StyledSource = styled(Anchor)`
  color: hsl(30, 40%, 55%);
  width: fit-content;
`;

const StyledBlurb = styled(Text)`
  word-wrap: break-word;
`;
