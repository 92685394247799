import { keyframes } from "styled-components";

export const HOVER_MOD_BRIGHTNESS = '150%'

export const BG_LAYER_0 = '#070707'
export const BG_LAYER_1 = '#121212'
export const BG_LAYER_2 = '#212121'
export const BG_LAYER_3 = '#303030'
export const BG_LAYER_4 = '#424242'
export const BG_LAYER_5 = '#545454'

export const FONT_COLOR_DARK = '#DDDDDD'
export const FONT_COLOR_LIGHT = '#333333'

export const COLOR_BRAND = '#007bff'

export const swell = keyframes`
  50%    {transform: scale(1.5);}
  100%  {transform: scale(1.0);}
`;

export interface GridProps {
  col1: string;
}