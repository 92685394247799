import React from 'react';
import { Grid, Box, Anchor } from 'grommet';
import { Previous, Close, Next } from 'grommet-icons';
import styled from 'styled-components';
import { COLOR_BRAND } from '../../styling/styles';
import { Document } from '../SearchResults';
import Pubmed, { PubmedArticle } from './PubmedArticle';
import Medscape, { MedscapeArticle } from './MedscapeArticle';
import LikeButton, { update_score } from '../../LikeButton';
import {BookmarkButton} from '../../Bookmarks';
import Radiopaedia_Article, { RadiopaediaArticle } from './RadiopaediaArticle';
import Gscholar, { GscholarArticle } from './GscholarArticle';
import ReportBroken from '../ReportBroken';

export type ArticleSources = 'radiopedia_article' | 'pubmed' | 'medscape' | 'medscape_ed' | 'gscholar'

export interface BaseArticle extends Document {
    _index: ArticleSources;
    _source: {};
    _type: 'article';
}

export type Article = PubmedArticle | MedscapeArticle | RadiopaediaArticle | GscholarArticle

export type ArticleProps = {
    document: Article;
    onClose: () => void;
    onPrev?: () => void;
    onNext?: () => void;
}

export default function ArticleDisplay(props: ArticleProps){
    const {document, onClose, onPrev, onNext} = props
    const {Left, Right} = ArticleSwitch(document)

    return(
        <Grid 
            fill 
            areas={[
                { name: "top", start: [0,0], end: [1, 0] },
                { name: "left", start: [0, 1], end: [0, 1] },
                { name: "right", start: [1, 1], end: [1, 1] }
            ]}
            columns={["flex", "33%"]}
            rows={["77px", "flex"]}
        >
            <Box gridArea="top" direction="row" justify="between" align="center" pad={{horizontal: "medium"}}>
                <span>
                <PrevArticle onClick={onPrev} hasPrev={typeof onPrev !== 'undefined'}/>
                <NextArticle onClick={onNext} hasNext={typeof onNext !== 'undefined'}/>
                </span>
                <CloseArticle onClick={onClose}/>
            </Box>
            <Box fill direction="column" gridArea="left" overflow={{horizontal: 'hidden', vertical: 'auto'}} gap="small" pad={{vertical: 'small', horizontal: 'medium'}}>
                {Left}
            </Box>
            <Box gridArea="right" pad={{horizontal: 'medium'}} gap="xsmall">
                <Box border={{color: "brand"}} overflow={{horizontal: 'hidden', vertical: 'auto'}} style={{minHeight: '33vh'}}>
                    <div style={{backgroundColor: COLOR_BRAND, textAlign: 'center', color: 'black'}}>Article Information</div>
                    <StyledGrid>
                        {Right}
                    </StyledGrid>
                </Box>
                <Box direction="row" justify="between" pad={{vertical: "small"}} gap="small" height="100%">
                    <Box direction="row" justify="between" gap="small" height="100%">
                        <LikeButton
                            id={document._id}
                            index={document._index}
                            score={document._source.popularity_score}
                            direction='row'
                            liked={document._liked}
                            onLike={(delta) => update_score(document, delta)}
                        />
                    <BookmarkButton id={document._id} bookmarked={document._bookmarked}/>
                    </Box>
                    <Box direction="row" justify="end">
                        <ReportBroken index={document._index} id={document._id} url={document._source.url}/>
                    </Box>
                </Box>
            </Box>
        </Grid>
    )
}

export type RowProps = {
    label: string,
    field: string | number | JSX.Element
}
export function Row({label, field}: RowProps) {
    return (
        <>
            <Label>{label}</Label>
            <Field>{field}</Field>
        </>
    )
}

type TitleProps = {
    text: string,
    url: string
}
export function Title(props: TitleProps){
    const {text, url} = props
    return(
        <Anchor size="xxlarge" color="brand" onClick={() => window.open(url)}>{text}</Anchor>    
    )
}

function ArticleSwitch(document: Article) {
    switch(document._index) {
        case('pubmed'):
            return Pubmed(document)
        case('medscape'):
            return Medscape(document)
        case('medscape_ed'):
            return Medscape(document)
        case('radiopedia_article'):
            return Radiopaedia_Article(document)
        case 'gscholar':
            return Gscholar(document)
    }
}

const CloseArticle = styled(Close)`
    stroke: ${COLOR_BRAND};
    &:hover {
        stroke: white;
        cursor: pointer;    
    }
`
const PrevArticle = styled(Previous)<{hasPrev: boolean}>`
    stroke: ${p => p.hasPrev ? COLOR_BRAND : 'grey'};
    &:hover {
        stroke: ${p => p.hasPrev ? 'white' : 'grey'};
        cursor: ${p => p.hasPrev ? 'pointer' : ''};    
    }
`
const NextArticle = styled(Next)<{hasNext: boolean}>`
    stroke: ${p => p.hasNext ? COLOR_BRAND : 'grey'};
    &:hover {
        stroke: ${p => p.hasNext ? 'white' : 'grey'};
        cursor: ${p => p.hasNext ? 'pointer' : ''};    
    }
`
const StyledGrid = styled.div`
    display: grid;
    grid-template-columns: 45% 55%;
    grid-template-rows: auto;
    row-gap: 12px;
    padding: 6px;
`
export const Label = styled.span`
    display: flex;
    grid-column: 1;
    align-self: left;
    justify-content: flex-start;
`

export const Field = styled.span`
    display: flex;
    grid-column: 2;
    justify-content: flex-end;
    flex-wrap: wrap;
    text-align: end;
`